import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorage } from 'ngx-store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Url } from 'src/app/models/users.model';
import { oauthConfig, xeroRoute } from './xero.config';

export interface XeroOauthConfig {
  issuer:        string,
  client_id:     string,
  redirect_uri:  string,
  scope:         string,
  response_type: string,
  code_exchange: string
}

@Injectable()
export class XeroService {
  @LocalStorage() url: Url;

  /**
   * Xero-related configuration for connecting via OAuth 2.0.
   */
  private oauthConfig: XeroOauthConfig;

  /**
   * @var {boolean} isConnected Whether the user is connected to Xero or not.
   */
  private _isConnected: boolean;

  constructor(
    private http:     HttpClient,
    private route:    ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    // load OAuth config from the server for an authorization code grant
    this.oauthConfig = oauthConfig;
    this.initConnectionStatus();
  }

  /**
   * Determine from cache whether the user is connected to Xero or not.
   */
  public get isConnected(): boolean {
    return this._isConnected;
  }

  /**
   * Authenticate to Xero through the OAuth2 protocol.
   */
  connect(): void {
    console.info('Client initiates Oauth 2.0 connection to Xero.');
    
    let xeroAuthUri = `${this.oauthConfig.issuer}`
      + `?response_type=${this.oauthConfig.response_type}`
      + `&client_id=${this.oauthConfig.client_id}`
      + `&redirect_uri=${location.origin}${this.oauthConfig.redirect_uri}`
      + `&scope=${this.oauthConfig.scope}`;

    let windowFeatures = 'width=512,height=670';

    let popup = window.open(xeroAuthUri, 'Connect to Xero', windowFeatures);
    console.debug('A pop-up window is opened.');
    
    window.addEventListener('message', (event) => {
      console.debug('Data is received from an external window.');

      let parentPath: string = window.location.origin;

      // guard against malicious source
      if(event.origin !== parentPath) {
        console.warn('Data is retrieved from an untrusted source.');
        console.info('Xero connection is aborted.');
        return;
      }

      let authcode = event.data?.code;

      if(authcode) {
        console.debug(`Xero authorization code "${authcode}" is extracted from the received data.`);
        this.relayAuthCode(authcode);
      }
      else {
        console.debug('No Xero authorization code is extracted from the data received.');
      }
    }, false);
  }

  disconnect(): void {
    this.http.delete(`${this.url.rest}${xeroRoute}`, {
      observe: 'response'
    }).subscribe((response: HttpResponse<any>) => {
      if(response.ok) {
        console.info('Server disconnects user from Xero.');
        this._isConnected = false;
        this.toastr.success('', "You are successfully disconnected to XERO", {
          timeOut: 5000,
          positionClass: 'toast-top-full-width',
          progressBar: true
        });
      }
      else {
        console.error('Server fails to disconnect user from Xero.');
        this.toastr.error("Server fails to disconnect user from Xero.", "", {
          timeOut: 5000,
          positionClass: 'toast-top-full-width',
          progressBar: true
        });
      }
    });
  }

  /**
   * Handle authentication code response, protected by the callback guard.
   * This must be called only in the callback page.
   * 
   * @param {string} str Window Location hash string.
   */
  handleCallback(): void {
    let popup = window;
    let parentWindow = popup.opener;
    let params = this.route.snapshot.queryParams;

    parentWindow.postMessage(params, popup.location.origin);
    popup.close();
  }

  /**
   * Send the authorization code gotten from Xero to our server.
   */
  private relayAuthCode(code: string) {
    console.debug('Xero authorization code is relayed to the server.');
    this.http
    .post(
      `${this.url.rest}${this.oauthConfig.code_exchange}`,
      { code },
      { observe: 'response' }
    )
    .subscribe((response: HttpResponse<any>) => {
      if(response.ok) {
        console.debug('Server converted the Xero authorization code for an access token.');
        console.info('Resource owner has successfully connected to its Xero account.');

        this._isConnected = true;
        this.toastr.success('', "You are successfully connected to XERO", {
          timeOut: 5000,
          positionClass: 'toast-top-full-width',
          progressBar: true
        });

        // this.router.navigate([  ]);
      }
      else {
        console.error('Server failed to convert the Xero authorization code for an access token.');
        this.toastr.error("Server failed to convert the Xero authorization code for an access token.", "", {
          timeOut: 5000,
          positionClass: 'toast-top-full-width',
          progressBar: true
        });
      }
    },
    () => console.debug('Server fails to convert the Xero authorization code for an access token.'));
  }

  /**
   * Get connection status.
   */
  private initConnectionStatus(): void {
    // query status from server
    this.requestConnectionStatusFrom(`${this.url.rest}${xeroRoute}`).subscribe((xeroInfo: any) => {
      // cache connection status
      this._isConnected = xeroInfo.tenant_id !== null;
    });
  }

  /**
   * Know whether the user is connected to Xero or not.
   */
  private requestConnectionStatusFrom(url: string): Observable<any> {
    return this.http.get(url);
  }

  /**
   * Create system invoices in Xero.
   * 
   * @param {Array.<number>} ids System invoice ids.
   */
  public createInvoices(ids: number[]) {
    let payload = { invoices: ids };
    return this.http.post(`${this.url.rest}${xeroRoute}/invoices`, payload, {
      observe: 'response'
    });
  }

  /**
   * Update invoices in Xero with the current information
   * of the system invoices whose IDs are given in the
   * arguments.
   * 
   * @param {Array.<number>} ids System invoice ids.
   */
  public updateInvoices(ids: number[]) {
    let payload = { invoices: ids };
    return this.http.put(`${this.url.rest}${xeroRoute}/invoices`, payload, {
      observe: 'response'
    });
  }

  public getContacts() {
    // this.http.get();
  }
}
