import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'DefaultRateSearchPipe', pure: false })
export class DefaultRateSearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(rate => {
        if (rate.service_type) {
          return rate.service_type.search(searchText) !== -1;
        }
      });
    }
  }
}