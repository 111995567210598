import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Settings } from '../app.settings.model';
import { retry, catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Url } from '../models/users.model';
import { LocalStorage } from 'ngx-store';

    

@Injectable()
export class FilesService {
    @LocalStorage() url: Url;

  	private headers = new Headers({ 'Content-Type': undefined });
    public settings: Settings;
    
    constructor(public http:HttpClient) { 
    }

    // Http Headers
    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    createFile(image_file : string) : Observable<string>
    {	
        return this.http.post<string>(this.url.rest + '/tms/upload/file', image_file, this.options)
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            )

    }

    uploadImageFile(folder: string,file: string) : Observable<string>{	
        return this.http.post<string>(this.url.rest+ '/tms/upload/image/' + folder, file, this.options)
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            )
    }

    uploadFile(folder: string,file: string) : Observable<string>{	
        return this.http.post<string>(this.url.rest+ '/tms/upload/file/' + folder, file, this.options)
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            )
    }

    uploadRawFile(folder: string, file: string, filename: string) : Observable<string>{    
        return this.http.post<string>(this.url.rest+ '/tms/upload/rawfile/' + folder + '/' + filename, file, this.options)
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            )
    }

    uploadRawFileWithRename(folder: string, file: string, filename: string) : Observable<string>{    
        return this.http.post<string>(this.url.rest+ '/tms/upload/uploadFileRawFileWithRename/' + folder + '/' + filename, file, this.options)
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            )
    }
    
    deleteFile(folder: string, fileName : string)
    {
    	return this.http.delete(this.url.rest + '/tms/delete/file/' + folder + '/' + fileName)
                        
    }

    generateFilename(filename: string)
    {
    	return this.http.get(this.url.rest+ '/tms/generateFilename/' + filename , this.options)
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            )
    }

    uploadFileWithoutRename(image_file : string) : Observable<string>
    {    
        return this.http.post<string>(this.url.rest+ '/tms/upload/fileWithoutRename', image_file, this.options)
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            )

    }

    private extractData(res: Response) {
        let body = res.json();
    	console.log(body);
        if(body)
            return body || {};
        else
            return null;
    }
    private handleError(error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }

    // Error handling
    errorHandl(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }

 }