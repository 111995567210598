import { XeroOauthConfig } from "./xero.service";

/**
 * Base route to the xero operations API
 */
export const xeroRoute: string = '/api/integrations/xero';

export const oauthConfig: XeroOauthConfig = {
    issuer:        'https://login.xero.com/identity/connect/authorize',
    client_id:     '4844AED4CA9A4FA38CC1F69770E3567C',
    redirect_uri:  '/invoices/create',
    scope:         'openid offline_access accounting.settings accounting.transactions accounting.contacts accounting.attachments',
    response_type: 'code',
    code_exchange: xeroRoute
};
