import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { JobNoticationsService } from '../../services/jobnotifications.service';
import { JobNotification } from '../../models/jobnotifications.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderPipe } from 'ngx-order-pipe';
import { DataService } from '../../services/data.service';
import *  as  helpData from '../../data/help/jobs_notifications.json';
import { Url } from '../../models/users.model';
import { LocalStorage } from 'ngx-store';

@Component({
  selector: 'app-jobnotification',
  templateUrl: './jobnotifications.component.html',
  styleUrls: ['./jobnotifications.component.scss', '../datatable.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [JobNoticationsService]
})
export class JobNotificationsComponent implements OnInit {
  @LocalStorage() url!: Url;
  @LocalStorage() customer: number;


  public jobnotifications: JobNotification[];
  public jobnotification: JobNotification;
  public searchText: string;
  public p: any;
  public type: string = 'list';
  public categories: any[];
  public drivers: any[];
  public controlNos: any[];
  public activities: any[];

  public rawJobnotications: JobNotification[];
  public selectCategory: string;
  public selectDriver: string;
  public selectControlNos: string;
  public selectActivity: string;

  public frmDate: FormGroup;
  public modalRef: NgbModalRef;
  public setFilter: boolean;

  private uploadPath: string;

  private order: string = 'created_at';
  private reverse: boolean = true;
  private sortedCollection: any[];
  help: any;
  loadingIndicator: boolean = true;
  count=0;




  constructor(public jobnotificationservice: JobNoticationsService,
    private fb: FormBuilder,
    public modalService: NgbModal,
    private orderPipe: OrderPipe, private data: DataService) {

    this.frmDate = this.fb.group({
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
    }, { validator: this.dateLessThan('start_date', 'end_date') });

    this.setFilter = true;
    this.uploadPath = this.url.fileStorage;

  }

  ngOnInit() {
    this.resetFilter();
    this.getJobCategories();
    this.getDrivers();
    this.getJobNotifications();
    this.getControlNos();
    this.getActivities();
    this.data.currentHelp.toPromise().then(help => this.help = help);

  }

  ngAfterViewInit() {
    this.data.changeHelp(helpData["default"]);
  }


  public dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (f.value > t.value) {
        return {
          dates: "Start date should be less than or equal to End date"
        };
      }
      return {};
    }
  }

  async getJobNotifications() {
    await this.jobnotificationservice.getJobNotificationsByCustomer(this.customer).toPromise().then(data => {
      this.jobnotifications = data["jobnotifications"];
      if(this.jobnotifications.length <= 0){
        this.count = 0
      }else{
        this.count = 1
      }
      this.rawJobnotications = data["jobnotifications"];

      //set sort
      this.sortedCollection = this.orderPipe.transform(this.jobnotifications, 'created_at');
    });
  }

  async getJobCategories() {
    await this.jobnotificationservice.getJobCategories().toPromise().then(data => {
      this.categories = data["jobcategories"];
      this.categories.push({ category_name: 'All' });
    });
  }

  async getDrivers() {
    await this.jobnotificationservice.getDriversByNotifications().toPromise().then(data => {
      this.drivers = data["drivers"];
      this.drivers.push({ driver_full_name: 'All' });
      this.drivers.push({ driver_full_name: 'No Assigned Driver' });

      var index = this.drivers.findIndex(item => item.driver_full_name === null);
      this.drivers.splice(index, 1);
    });
  }

  async getControlNos() {
    await this.jobnotificationservice.getJobControlNos().toPromise().then(data => {
      this.controlNos = data["jobcontrolno"];
      this.controlNos.push({ control_no: 'All' });
      this.controlNos.push({ control_no: 'No Assigned Control No' });

      var index = this.controlNos.findIndex(item => item.control_no === null);
      this.controlNos.splice(index, 1);
    });
  }

  async getActivities() {
    await this.jobnotificationservice.getActivities().toPromise().then(data => {
      this.activities = data["activities"];
      this.activities.push({ activity_name: 'All' });
      this.activities.push({ activity_name: 'No Activity Type' });

      var index = this.activities.findIndex(item => item.activity_name === null);
      this.activities.splice(index, 1);

      setTimeout(() => { this.loadingIndicator = false; }, 1500);
    });

  }

  public filterTable(event: string, filterType: string) {
    this.jobnotifications = this.rawJobnotications.filter(data => {
      switch (filterType) {
        case "category": {
          this.selectDriver = 'Filter by Driver';
          this.selectControlNos = 'Filter by Control No';
          this.selectActivity = 'Filter by Activity';
          this.setFilter = true;
          this.searchText = "";


          if (event == 'All') {
            return data;
          } else {
            return data.category_name == event;
          };
        };
        case "driver": {
          this.selectCategory = 'Filter by Category';
          this.selectControlNos = 'Filter by Control No';
          this.selectActivity = 'Filter by Activity';
          this.setFilter = true;
          this.searchText = "";


          if (event == 'All') {
            return data;
          } else {
            if (event == 'No Assigned Driver') {
              return data.driver_full_name == null || data.driver_full_name == ' ';
            } else {
              return data.driver_full_name == event;
            }
          };
        };
        case "controlno": {
          this.selectCategory = 'Filter by Category';
          this.selectDriver = 'Filter by Driver';
          this.selectActivity = 'Filter by Activity';
          this.setFilter = true;
          this.searchText = "";


          if (event == 'All') {
            return data;
          } else {
            if (event == 'No Assigned Control No') {
              return data.job_control_no == null || data.job_control_no == ' ';
            } else {
              return data.job_control_no == event;
            }
          };
        };

        case "activity": {
          this.selectCategory = 'Filter by Category';
          this.selectDriver = 'Filter by Driver';
          this.selectControlNos = 'Filter by Control No';
          this.setFilter = true;
          this.searchText = "";


          if (event == 'All') {
            return data;
          } else {
            if (event == 'No Activity Type') {
              return data.activity_name == null || data.activity_name == ' ';
            } else {
              return data.activity_name == event;
            }
          };
        };
        default: {
          return data;
        }
      }
    });
  }

  public resetFilter(): void {
    this.getJobNotifications();
    this.selectCategory = 'Filter by Category';
    this.selectDriver = 'Filter by Driver';
    this.selectControlNos = 'Filter by Control No';
    this.selectActivity = 'Filter by Activity';
    this.setFilter = true;
    this.searchText = "";
  }

  public openModal(modalContent) {
    this.modalRef = this.modalService.open(modalContent, { container: '.app' });

    this.modalRef.result.then((result) => {
      this.frmDate.reset();
    }, (reason) => {
      this.frmDate.reset();
    });
  }

  public closeModal() {
    this.modalRef.close();
  }

  public onSubmit(event) {
    this.selectCategory = 'Filter by Category';
    this.selectDriver = 'Filter by Driver';
    this.selectControlNos = 'Filter by Control No';
    this.selectActivity = 'Filter by Activity';
    this.searchText = "";
    this.setFilter = false;

    if (this.frmDate.valid) {
      this.jobnotifications = this.rawJobnotications.filter(data => {
        return data.created_at >= this.frmDate.get('start_date').value && data.created_at <= this.frmDate.get('end_date').value
      });
    }

    this.modalRef.close();
  }

  public removeFilter() {
    this.setFilter = true;
    this.jobnotifications = this.rawJobnotications.filter(data => {
      return data
    });
  }

  public renderHtml(notification: JobNotification) {
    var html = notification.text;
    if (notification.job_id != null) {
      html = html.replace(new RegExp(notification.job_control_no), match => {
        return '<a class="link" title="View Job" href="bookings/jobdetails/' + notification.job_id + '">' + match + '</a>';
      });
    }

    // if (notification.driver_id != null) {
    //   html = html.replace(new RegExp(notification.driver_full_name), match => {
    //     return '<a class="link" title="View Driver" href="drivers/driverslist/driverdetails/' + notification.driver_id + '">' + match + '</a>';
    //   });
    // }

    if (notification.link != null) {

      //checks if there is no match key and also no job_id and driver_id present in the notification.text. If true, puts the whole text as link to the document. 
      //else it only makes the keywords the activated link.

      var matched = html.match(/(file|Report|Repair|Refueled)/)

      if (matched == null && notification.job_id == null && notification.driver_id == null) {
        html = '<a class="link" title="View Document" target="_blank" href="' + this.url.fileStorage + '/' + notification.link + '">' + notification.text + '</a>'
      }
      else {
        html = html.replace(new RegExp("file|Report|Repair|Refueled"), match => {
          return '<a class="link" title="View Document" target="_blank" href="' + this.url.fileStorage + '/' + notification.link + '">' + match + '</a>'
        });
      }

    }

    return html;
  }

  public setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }


}
