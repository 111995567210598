import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import { Search } from '../models/search.model';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../models/users.model';
import { LocalStorage } from 'ngx-store';


@Injectable()
export class SearchService {
    @LocalStorage() url: Url;

      private headers = new Headers({ 'Content-Type': 'application/json' });
      
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }
    constructor(public http:HttpClient) {
    }
	getSearchKeys(): Observable<Search[]> { 
		return this.http.get<Search[]>(this.url.rest + '/customers/getCustomerSearchKeys', this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        )
    }

    getSearchKeyById(id:number): Observable<Search> { 
        return this.http.get<Search>(this.url.rest + '/customers/getCustomerSearchKeyById/' + id, this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        ) 
    }

      // Error handling
      handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }

}