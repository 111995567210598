import { Injectable } from '@angular/core';
import { JobNotification } from '../models/jobnotifications.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Url } from '../models/users.model';
import { LocalStorage } from 'ngx-store';

@Injectable()
export class JobNoticationsService {
    @LocalStorage() url: Url;

    public headers = new Headers({ 'Content-Type': 'application/json' });

    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }


    constructor(public http: HttpClient) {
    }

    getJobNotifications(): Observable<JobNotification[]> {
        return this.http.get<JobNotification[]>(this.url.rest + '/tms/getJobNotifications', this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }


    getJobNotificationsById(id: number): Observable<JobNotification> {
        return this.http.get<JobNotification>(this.url.rest + '/tms/getJobNotificationById/' + id, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    addJobNotification(jobnotification: JobNotification): Observable<JobNotification> {
        return this.http.post<JobNotification>(this.url.rest + '/tms/createJobNotification', jobnotification, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    updateJobNotification(jobnotification: JobNotification): Observable<JobNotification> {
        return this.http.put<JobNotification>(this.url.rest + '/tms/updateJobNotification', jobnotification, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    deleteJobNotification(id: number) {
        return this.http.delete(this.url.rest + '/tms/deleteJobNotification/' + id);
    }

    getJobCategories(): Observable<any[]> {
        return this.http.get<any[]>(this.url.rest + '/tms/getJobCategories', this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    getDriversByNotifications(): Observable<any[]> {
        return this.http.get<any[]>(this.url.rest + '/tms/getDriversByNotifications', this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    getJobControlNos(): Observable<any[]> {
        return this.http.get<any[]>(this.url.rest + '/tms/getJobControlNos', this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    getActivities(): Observable<any[]> {
        return this.http.get<any[]>(this.url.rest + '/tms/getActivities', this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    getJobNotificationsByCustomer(customer_id): Observable<JobNotification[]> { 
        return this.http.get<JobNotification[]>(this.url.rest  + '/customers/getJobNotificationsByCustomer/' + customer_id, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )    
    }


    private extractData(res: Response) {
        let body = res.json();
        console.log(body);
        if (body)
            return body || {};
        else
            return null;
    }

    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }

} 