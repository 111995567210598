import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ContactSearchPipe', pure: false })
export class ContactSearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(contact => {
        if (contact.name) {
          return contact.name.search(searchText) !== -1;
        }
      });
    }
  }
}