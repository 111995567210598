import { URI, URIs } from './uri';

export { Endpoint, Endpoints };

/**
 * HTTP Endpoint definition. Unlike the `URI` interface, the `route`
 * property in this must be absolute.
 * 
 */
interface Endpoint extends URI {
    /**
     * HTTP method. Use `*` to indicate all methods.
     */
    method: 'get' | 'post' | 'put' | 'delete' | '*' | string
}

/**
 * Collection of endpoints.
 */
class Endpoints extends URIs {
    constructor(endpoints: Endpoint[]) {
        super(endpoints);
    }

    /**
     * Check whether an endpoint is included in this collection
     * of endpoints.
     * 
     * @param subject Endpoint to check.
     */
    public includes(subject: Endpoint): boolean {
        let subjectRoute: string = subject.route as string;
    
        return this.collection.some((endpoint: Endpoint) => {
            return (
                // endpoint methods match
                (endpoint.method === '*' || subject.method.toLowerCase() === endpoint.method.toLowerCase()) &&

                // endpoint routes match
                (
                    // as string
                    (typeof endpoint.route === 'string' && subjectRoute.toLowerCase() === endpoint.route.toLowerCase()) ||

                    // as RegExp
                    (endpoint.route instanceof RegExp && subjectRoute.search(endpoint.route) !== -1)
                )
            );
        });
    }
}