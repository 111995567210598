/*** 
Oct 11, 2017 KA: Added this model for members page.
Aug 06, 2016 KA: Updated to add subrole_id in the user form.
****/

export class User {
	id				: 	number;
	username		: 	string; 
	email			:	string;
	password		: 	string;
	display_name	:	string;
	joined			:	string;
	status			:	string;
	avatar			:	string;
	role_id			:	string;
	signature_file	:	string;
	reminder		:	string;
	remember		:	string;
	subrole_id		: 	string;
}


export class Profile {
	id				: number;
	user_id			: number;
	first_name		: string;
	middle_name		: string;
	last_name		: string;
	gender			: number;
	birthdate		: string;
	mobile_no		: string;
	phone_no		: string;
	address			: string;
	address_lat		: string;
	address_lng		: string;
}

export class Account {
	id				: 	number;
   	username		: 	string; 
   	email			:	string;
}


export class Password {
	id	: number;
	old_password	: string;
	confirm_password: string;
	new_password	: string;
}


export class ThemeSetting {
	id	: number;
	theme	: string;
	menu: string;
	menu_type	: string;
	is_navbar_fixed: number;
	is_sidebar_fixed: number;
	is_footer_fixed: number;
}


export class Url {
	app: string;
	rest: string;
	fileStorage: string;
	cloudStorage: string;
  }


  export class Customer {
	id				: number;
	user_id			: number;
	company_name	: string;
	contact_name	: string;
	job_type_id		: number;
	abn				: string;
	acn				: string;
	phone_no		: string;
	mobile_no		: string;
	fax_no			: string;
	billing_address	: string;
	city			: string;
	state			: string;
	postal_code		: string;
	country_region	: string;
	first_email		: string;
	second_email	: string;
	file_logo		: string;
	//'invoice_note_id'		: number;
	invoice_bank_id	: number;
	payment_term_id	: number;
	days_due		: string;
	default_fuel_levy	: string;
	default_service_fee	: string;
	default_job_type_id	: number;
	default_charging_method_id	: number;
	default_service_type_id		: number;
	default_contact_name		: string;
	reference_no_prefix			: string;
	username		: string;
	login_email		: string;
	role_id			: number;
	bank_name		: string;
	bsb				: string;
	acct_no			: string;
	acct_name		: string;
}