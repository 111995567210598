import { Endpoints } from '../../models/endpoint';
import { AuthStorage, Token } from '../../models/oauth';
import { URIs } from '../../models/uri';

export {
    publicServerEndpoints,
    publicClientRoutes,
    tokenStorage,
    loginUri,
    loggedInUri,
    loggedOutUri,
    AuthResponse,
    grantStorage,
    extractTokenFrom
};

/**
 * Client navigation routes that don't care whether the user is authenticated or
 * not. The `route` specified in here must be relative to the client's domain name.
 */
const publicClientRoutes = new URIs([
    // { route: '/landingpage' },
]);

/**
 * Relative server endpoints that do not need the client to be authenticated.
 * 
 * Note that unlike `publicClientEndpoints` where the route is defined relatively,
 * the `route` property in here must be absolute, otherwise the client couldn't
 * tell what endpoints to attach authentication credentials when accessing server
 * resource.
 */
const publicServerEndpoints = new Endpoints([
    // server authentication routes itself
    {
        method: '*',
        route: /customers\/login/
    },
    {
        method: 'get',
        route: /customers\/getAuthClientId/
    },
    {
        method: 'post',
        route: /oauth\/token/
    },
    {
        method: 'post',
        route: 'https://empress.logicor.net.au/api/getDomain'
    },
    {
        method: 'post',
        route: 'https://empress.idrv.app/api/getDomain'
    }
]);

/**
 * Instruct the client to use LocalStorage API for persisting token data
 */
const tokenStorage: AuthStorage = {
    // define where/how to store token data
    set: localStorage.setItem.bind(localStorage),
  
    // define how to get token data persisted from storage
    get: localStorage.getItem.bind(localStorage),
  
    // dictate how to remove token data off the storage
    unset: localStorage.removeItem.bind(localStorage),
  
    // set custom token data namespace to mitigate naming collisions
    prefix: 'oauth'
}

/**
 * Instruct the client to use LocalStorage 
 */
const grantStorage: AuthStorage = { ...tokenStorage, prefix: 'client_creds' };

/**
 * Define the login URI to temporarily redirect unauthenticated users to when
 * trying to access protected endpoints. Value must be relative (starting with
 * `/`).
 */
const loginUri: string = '/login';

/**
 * Define the default route to redirect the user to after he/she has
 * successfully logged in. Value must be relative (starting with `/`).
 */
const loggedInUri: string = '/';

/**
 * Define the route to redirect the user to after he/she has successfully
 * logged out.
 */
const loggedOutUri: string = loginUri;

/**
 * Define the structure of the response after successful authentication.
 */
interface AuthResponse {
    token: Token,
    admin_user: any,
    admin_profile: any,
    theme_settings: any
    customer: any
}

/**
 * Instruct the authentication service on how to extract the token
 * credentials from a successful authentication response.
 * 
 * @param {Token} response Response object returned on successful
 *                         authentication.
 */
function extractTokenFrom(response: AuthResponse): Token {
    return response.token;
}