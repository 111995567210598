import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any [], entries: any): any [] {
        if (!items) return[];
        if (entries === undefined) return items;

        return items.filter(function (item) {
            for (let property in item) {

                if (item[property] === null) {
                    continue;
                }
                if (item[property].toString().toLowerCase().includes(entries.toLowerCase())) {
                    return true;
                }

            }
            return false;
        });
    }

}