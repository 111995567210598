import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../core/authentication/auth.guard";
import { XeroModule } from "./xero/xero.module";

const routes: Routes = [
  {
    path: 'xero',
    loadChildren: () => import('./xero/xero.module').then(m => m.XeroModule),
    canLoad: [AuthGuard]
  }
];

@NgModule({
  imports: [
    XeroModule,
    RouterModule.forChild(routes)
  ]
})
export class IntegrationsModule { }
