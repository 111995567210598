import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from 'src/lib/core/authentication/auth.guard';
import { CallbackGuard } from './callback.guard';
import { CallbackComponent } from './callback/callback.component';
import { ConnectionButtonComponent } from './connection-button/connection-button.component';
import { SetupComponent } from './setup/setup.component';
import { XeroService } from './xero.service';

const routes: Routes = [
  {
    path: 'callback',
    component: CallbackComponent,
    canActivate: [CallbackGuard]
  },
  {
    path: 'setup',
    component: SetupComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    ConnectionButtonComponent,
    CallbackComponent,
    SetupComponent
  ],
  imports: [
    CommonModule,
    ToastrModule,
    NgbModule,
    RouterModule.forChild(routes)
  ],
  providers: [
    XeroService,
    CallbackGuard
  ],
  exports: [
    ConnectionButtonComponent
  ]
})

/**
 * This module connects a browser client to Xero.
 */
export class XeroModule { }