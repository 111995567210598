import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Endpoint } from '../../models/endpoint';
import { Token } from '../../models/oauth';
import { publicServerEndpoints } from './auth.config';
import { AuthService } from './auth.service';

/**
 * Set `Authorization` header if user has the credentials.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let requestEndpoint: Endpoint = {
      method: request.method,
      route: request.url
    };

    // check whether the request endpoint is not protected
    if(publicServerEndpoints.includes(requestEndpoint)) {
      // pass on the request unmodified
      return next.handle(request);
    }

    // if request is in order to refresh the access token
    if(JSON.stringify(requestEndpoint).toLowerCase() == JSON.stringify(this.auth.grantCreds.refreshEndpoint).toLowerCase()) {
      // pass unmodified, otherwise infinite interceptions will occur when refreshing token
      return next.handle(request);
    }

    console.debug('Request is intercepted.');

    // check if the client does not have access token
    if(!this.auth.token) {
      console.debug('Access token is missing.');

      // intercept the request and send back an unauthorized response
      return throwError(new HttpResponse({
        status: 401,
        body: {
          'message': 'Unauthenticated.',
          'hint': 'This route is protected. Please authenticate first.'
        }
      }));
    }

    // check if the token is expired
    if(this.auth.isExpired) {
      console.debug('Access token is expired.');

      // refresh token then send request with the attached credentials
      return this.auth.refreshToken()
      .pipe(
        switchMap((token: Token): Observable<HttpEvent<any>> => {
          let authRequest = this.addAuthHeader(request, token);
          return next.handle(authRequest);
        })
      );
    }

    // if client has a valid access token
    console.debug('Access token is attached to the authorization header of the request.');

    let authRequest = this.addAuthHeader(request, this.auth.token);
    return next.handle(authRequest);
  }

  /**
   * Attach the Bearer token to the `Authorization` HTTP header.
   * 
   * @param {HttpRequest<unknown>} request The HTTP request to attach the token to.
   * @param {Token} token Token to attach.
   */
  private addAuthHeader(request: HttpRequest<unknown>, token: Token) {
    let authRequest: HttpRequest<unknown> = request.clone({
      setHeaders: {
        Authorization: `${token.token_type} ${token.access_token}`
      }
    });

    return authRequest;
  }
}