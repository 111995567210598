import { Menu } from './menu.model';

export const verticalMenuItems = [ 

    //Dashboard
    new Menu (1, 'Dashboard', '/', null, 'tachometer', null, false, 0),

     //Bookings
     new Menu(2, 'Bookings', 'bookings', null, 'calendar-check-o', null, true, 0),
     new Menu(21, 'New Booking', 'bookings/addbooking', null, 'calendar-plus-o', null, false, 2),
     new Menu(22, 'Activities', 'activities', null, 'file-text', null, false, 2),

    //Compliance
    new Menu (3, 'Compliance', null, null, 'calendar', null, true, 0),
    new Menu (31, 'Documents', '/compliance', null,'file', null, false, 3),
    new Menu (32, 'Requests', 'compliance/compliancerequests', null, 'exclamation-circle', null, false, 3),
    
    //Tracker
    new Menu(4, 'Tracker', 'maptracker', null, 'map-marker', null, false, 0),

    //Settings
    new Menu(5, 'Settings', null, null, 'cogs', null, true, 0),
    new Menu(51, 'My Profile', 'profile', null, 'user', null, false, 5),
    new Menu(52, 'Contacts', 'contacts', null, 'address-card', null, false, 5),
    new Menu(53, 'Rates', 'rates', null, 'credit-card-alt', null, false, 5),

    //Support
    new Menu(6, 'Support', 'support', null, 'envelope-o', null, false, 0),

    //Logicor Search
    new Menu (200, 'Logicor Website', null, 'http://logicor.net.au', 'external-link', '_blank', false, 0)
]

export const horizontalMenuItems = [ 

    //Dashboard
    new Menu (1, 'Dashboard', '/', null, 'tachometer', null, false, 0),

    //Bookings
    new Menu(2, 'Bookings', 'bookings', null, 'calendar-check-o', null, true, 0),
    new Menu(21, 'New Booking', 'bookings/addbooking', null, 'calendar-plus-o', null, false, 2),
    new Menu(22, 'Activities', 'activities', null, 'file-text', null, false, 2),
    
    //Compliance
    new Menu (3, 'Compliance', null, null, 'calendar', null, true, 0),
    new Menu (31, 'Documents', '/compliance', null,'file', null, false, 3),
    new Menu (32, 'Requests', 'compliance/compliancerequests', null, 'exclamation-circle', null, false, 3),
    
    //Tracker
    new Menu(4, 'Tracker', 'maptracker', null, 'map-marker', null, false, 0),

    //Settings
    new Menu(5, 'Settings', null, null, 'cogs', null, true, 0),
    new Menu(51, 'My Profile', 'profile', null, 'user', null, false, 5),
    new Menu(52, 'Contacts', 'contacts', null, 'address-card', null, false, 5),
    new Menu(53, 'Rates', 'rates', null, 'credit-card-alt', null, false, 5),
    
    //Support
    new Menu(6, 'Support', 'support', null, 'envelope-o', null, false, 0),

    //Logicor Search
    new Menu (200, 'Logicor Website', null, 'http://logicor.net.au', 'external-link', '_blank', false, 0)
  
]