import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MapData, PanelData } from '../models/maptracker.model';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Url } from '../models/users.model';
import { LocalStorage } from 'ngx-store';


@Injectable()
export class MapTrackerService {
    @LocalStorage() url: Url;

    constructor(public http: HttpClient) {
    }

    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
    })
    };

	getDashboardData(): Observable<PanelData> {
		return this.http.get<PanelData>(this.url.rest  + '/tms/getDashboardData', this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    getCustomerMaptracker(customerId): Observable<MapData> {
		return this.http.get<MapData>(this.url.rest  + '/tms/getActiveDrivers/' + customerId, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }


    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }

}
