export { URI, URIs }

interface URI {
    /**
     * Relative URI, excluding the domain and protocol.
     * Regular Expression is also supported.
     */
    route: string | RegExp
}

/**
 * Collection of URIs.
 */
class URIs {
    protected collection: URI[];

    constructor(endpoints: URI[]) {
        this.collection = endpoints;
    }

    /**
     * Check whether an endpoint is included in this collection
     * of endpoints.
     * 
     * @param subject Endpoint to check.
     */
    public includes(subject: URI): boolean {
        let subjectRoute: string = subject.route as string;
    
        return this.collection.some((endpoint: URI) => {
            return (
                // endpoint routes match
                (
                    // as string
                    (typeof endpoint.route === 'string' && subjectRoute.toLowerCase() === endpoint.route.toLowerCase()) ||

                    // as RegExp
                    (endpoint.route instanceof RegExp && subjectRoute.search(endpoint.route) !== -1)
                )
            );
        });
    }
}