import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CallbackGuard implements CanActivate {
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    let currWindow = window as any;
    let parentWindow = currWindow.opener;
    
    // if this consent window is not opened by clicking the "Connect with Xero" button
    if(!parentWindow) {
      currWindow.alert(
        'There\'s nothing to do here. Please authenticate properly by ' +
        'clicking the "Connect with Xero" button.'
      );
      console.warn('User is expected to navigate only to this view via a consent popup window.');
      console.info('Please authenticate properly by clicking the "Connect with Xero" button.');
      return false;
    }
    return true;
  }
  
}
