// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import 'zone.js';
import 'zone.js/dist/zone-error';
export const environment = {
  production: false,
  reusablePaths: {
    SAMPLE_PATH_NAME: 'sample'
    // PATH_NAME: '<path name in route>' // Reusable paths are paths that store their state
  },
  companyCode: 'stg',
  loginFooter: '[STAGING] iDrv Customer Portal (3.0.32)', // to be used only for customised login pages
  protocolIdentifier: 'https://',
  restApiForClientData: 'https://empress.idrv.app/api/getDomain',

  website: 'http://logicor.net.au',

  firebaseConfig : {
    apiKey: 'AIzaSyDl5kmChStF-EDdXk7dt2mOfZpx0y-vn_U',
    authDomain: 'maptrack-a8b51.firebaseapp.com',
    databaseURL: 'https://maptrack-a8b51.firebaseio.com',
    projectId: 'maptrack-a8b51',
    storageBucket: 'maptrack-a8b51.appspot.com',
    messagingSenderId: '350244137322',
    appId: '1:350244137322:web:96054bdc9d044516499d13',
    measurementId: 'G-5WC31PMH4K'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
