import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { PeopleContactsService } from '../../services/peoplecontacts.service';
import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data.service';
import *  as  helpData from '../../data/help/contacts_peopleContacts.json';
import { Url, User } from '../../models/users.model';
import { LocalStorage } from 'ngx-store';
import { ThemeSetting } from '../../models/users.model';

/*** Export List Feature Imports ***/
import { ExportService } from '../../services/exporter/exporter.service';
import { FileExport } from '../../services/exporter/exporter.model';
import { formatDate } from '@angular/common';
//import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import { PeopleContact, ContactAffiliate, ContactTypes } from '../../models/peoplecontacts.model'
import { Contact } from '../../models/customers.model';
import { FilesService } from './../../services/files.service';
import { ImageResult } from 'ngx-image2dataurl';
import { ToastrService } from 'ngx-toastr';





@Component({
  selector: 'app-peoplecontacts',
  templateUrl: './peoplecontacts.component.html',
  styleUrls: ['./peoplecontacts.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [PeopleContactsService, FilesService]
})

export class PeopleContactsComponent implements OnInit {
  @LocalStorage() url!: Url;
  @LocalStorage() themeSettings: ThemeSetting;
  @LocalStorage() user: User;
  @LocalStorage() customer: number;


  public filter: string = 'Customer';
  public id: number ;

  @ViewChild('modalContact', { static: false }) modalContact: TemplateRef<any>;
  @ViewChild('modalAffiliateContact', { static: false }) modalAffiliateContact: TemplateRef<any>;
  @ViewChild('modalDeleteContact', { static: false }) modalDeleteContact: TemplateRef<any>;
  @ViewChild('modalDeleteAffiliation', { static: false }) modalDeleteAffiliation: TemplateRef<any>;



  public fileStoragePath = this.url.fileStorage;
  public settings: Settings;
  public cp: any;
  public sp: any;

  sub: Subscription;
  rows = [];
  selected = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  contacts: any[];

  showFilters: boolean = false;
  public searchText: string;
  temp = [];
  fullRows = [];
  isFilterEmpty: boolean = true;
  count = 0;
  filterCompanyName: string = '';
  filterContactType: string = '';
  filterPhone: string = '';
  filterAddress: string = '';
  filterEmail: string = '';
  filterPosition: string = '';

  showName: boolean = true;
  showPosition: boolean = true;
  showContactType: boolean = true;
  showCompany: boolean = true;
  showEmail: boolean = true;
  showMobile: boolean = true;
  showPhone: boolean = true;
  showAddress: boolean = true;
  showSupplier: boolean = true;
  showFax: boolean = true;
  showABN: boolean = true;

  public contact: PeopleContact;
  currentContact: PeopleContact;
  tableType = 'list';
  p: string;
  help: any;
  private readonly prefixPpl = 'contacts';
  private readonly contacts_folder_path = this.fileStoragePath + "/contacts";
  public showSidebar: boolean = true;

  //Handles the name, type and content of the to-be exported file
  public exp_excel: FileExport;
  public exp_pdf: FileExport;

  //Stores the filtered and formatted content of the file
  formatted_list: any;

  public contactForm: FormGroup;
  public affiliateForm: FormGroup;
  public contactTypesForm: FormGroup;

  customers = [];
  suppliers = [];
  contact_types = [];
  supplier_contacts = [];
  customer_contacts = [];
  isUpdateMode: boolean = false;


  public photo: string;
  public picContactExists: boolean;
  public picContactRemove: boolean;
  public oldphoto: string;

  public contact_affiliate: ContactAffiliate;


  constructor(private http: HttpClient, private modal: NgbModal, private _fb: FormBuilder,
    private pplContactService: PeopleContactsService,
    private _Activatedroute: ActivatedRoute,
    public appSettings: AppSettings,
    private data: DataService,
    private exporter: ExportService,
    private fileService: FilesService,
    private toastr: ToastrService,) {

    this.settings = appSettings.settings;
    this.sub = this._Activatedroute.params.subscribe(params => {
    });

    this.photo = '';
    this.picContactExists = false;
    this.picContactRemove = false;
    this.oldphoto = '';
  }

  ngOnInit() {
    this.id = this.customer; // setting as customer contact
    if (!this.filter) {
      this.filter = 'All';
    }
    this.loadingIndicator = true;
    this.getPplContacts();
    this.data.currentHelp.subscribe(help => this.help = help);

    //Declaration to handle the name, type and content of the file
    this.exp_excel = { title: '', list_data: '', type: '' };
    this.exp_pdf = { title: '', list_data: '', type: '' };


  }

  ngAfterViewInit() {
    this.data.changeHelp(helpData["default"]);
  }

  onSelect({ selected }) {
    // console.log('Select Event', selected, this.selected);
    this.getCompanyAffiliates(this.selected[0].id)
    this.currentContact = this.selected[0];
  }

  onActivate(event) {
    if (event.type === 'mouseenter') {
      // Code
      this.selected[0] = event.row;
    }
  }

  initializeForms() {
    const email = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
    this.contact = new PeopleContact();
    this.contact_affiliate = new ContactAffiliate();
    this.currentContact = null;
    this.currentAffiliation = null;

    this.contactForm = this._fb.group({
      id: [''],
      position: [''],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      phone: [''],
      mobile: [''],
      email: ['', [Validators.required, Validators.pattern(email)]],
      fax: [''],
      address: [''],
      abn: [''],
      photo: [''],
      created_id: [this.user.id],
      updated_id: [''],
    });

    this.affiliateForm = this._fb.group({
      id: [''],
      contact_id: ['', Validators.required],
      company_id: ['', Validators.required],
      dedicated_phone: [''],
      dedicated_email: ['', Validators.pattern(email)],
      dedicated_mobile: [''],
      created_id: [this.user.id],
      updated_id: [''],
      type: ['', Validators.required],
      contact_types: this._fb.array([this.initContactTypes()])
    });

    this.getContactTypes();
  }

  public initContactTypes() {
    return this._fb.group({
      id: [''],
      type_name: [''],
      company_contact_id: ['', Validators.required],
      contact_type_id: ['', Validators.required],
      email_notification: [0],
      sms_notification: [0],
      is_enabled: [0]
    });

  }

  public getContactTypes() {
    var addCtrl = <FormArray>this.affiliateForm.controls.contact_types;
    addCtrl.removeAt(0);
    this.contact_types.forEach(item => {
      addCtrl.push(this.setContactType(item));
    })
  }

  public updateContactTypesForm(currentContactTypes) {
    this.contact_types.forEach(ct => {
      let item_found = null;
      item_found = currentContactTypes.filter(data => { return data.contact_type_id == ct.id });
      console.log(item_found, currentContactTypes, ct, 'found');
      if (!item_found[0]) {
        let new_item = { id: 0, company_contact_id: 0, contact_type_id: ct.id, email_notification: 0, is_enabled: 0, sms_notification: 0, type_name: ct.name };

        this.contact_affiliate.contact_types.push(new_item);
      }
    })
    console.log(this.affiliateForm, 'FORM')
  }

  private setContactType(ctype) {
    return this._fb.group({
      contact_type_id: [ctype.id],
      company_contact_id: [0],
      email_notification: [0],
      sms_notification: [0],
      type_name: [ctype.name],
      is_enabled: [0]
    });
  }

  private getPplContacts() {
    // let query_filter = "all";
    // let id = null;

    // if (this.filter) {
      let query_filter = this.filter;
      let id = this.id;
    // };

    this.pplContactService.getPeopleContactList(query_filter, id).toPromise().then(data => {

      this.rows = data["people_contacts"];
      this.customers = data["customers"];
      this.suppliers = data["suppliers"];
      this.contact_types = data["contact_types"];
      this.supplier_contacts = data["supplier_contacts"];
      this.customer_contacts = data["customer_contacts"];
      console.log(this.selected, 'selected');
      if (this.rows[0]) {
        this.selected = [data["people_contacts"][0]];
        this.getCompanyAffiliates(this.selected[0].id)
        this.currentContact = this.selected[0];
      } else {
        this.selected = [];
      };

      this.fullRows = this.rows;
      this.temp = this.rows;
      this.count = Object.keys(this.rows).length;

      this.initializeForms();
    }).finally(() => setTimeout(() => { this.loadingIndicator = false; }));



  }


  supplier_company_affiliates = [];
  customer_company_affiliates = [];

  getCompanyAffiliates(contact_id) {
    let raw_sc_companies = this.supplier_contacts;
    let raw_cc_companies = this.customer_contacts;

    if (this.filter == 'All' || this.filter == 'Supplier') {
      this.supplier_company_affiliates = raw_sc_companies.filter(data => {
        return data.contact_id == contact_id;
      });

      if(this.filter =='Supplier'){
        this.supplier_company_affiliates = this.supplier_company_affiliates.filter(data => {
          return data.supplier_id == this.id;
        });
      }
    };

    if (this.filter == 'All' || this.filter == 'Customer') {
      this.customer_company_affiliates = raw_cc_companies.filter(data => {
        return data.contact_id == contact_id;
      });

      if(this.filter =='Customer'){
        this.customer_company_affiliates = this.customer_company_affiliates.filter(data => {
          return data.customer_id == this.id;
        });
      }

    }

  }

  resetFilters() {
    this.filterCompanyName = '';
    this.searchText = '';
    this.filterContactType = '';
    this.filterPhone = '';
    this.filterAddress = '';
    this.filterEmail = '';
    this.filterPosition = '';
    this.getPplContacts();

  }

  updateFilter() {
    this.isFilterEmpty = false;
    this.rows = this.fullRows;
    this.temp = this.fullRows;

    if (this.filterCompanyName) {
      this.temp = this.fullRows.filter(data => {
        if (data.company_name && this.filterCompanyName) {
          return data.company_name.toLowerCase().indexOf(this.filterCompanyName.toLowerCase()) !== -1 || !this.filterCompanyName.toLowerCase();
        }
        else {
          return data.company_name
        };
      });
    }

    if (this.searchText) {
      this.temp = this.temp.filter(data => {
        if ((data.first_name + ' ' + data.last_name) && this.searchText) {
          return (data.first_name + ' ' + data.last_name).toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1 || !this.searchText.toLowerCase();
        }
        else {
          return data.first_name + data.last_name
        };
      });
    }

    if (this.filterPhone) {
      this.temp = this.temp.filter(data => {
        if (data.phone && this.filterPhone) {
          return data.phone.toLowerCase().indexOf(this.filterPhone.toLowerCase()) !== -1 || !this.filterPhone.toLowerCase();
        }
        else {
          return data.phone
        };
      });
    }

    if (this.filterAddress) {
      this.temp = this.temp.filter(data => {
        if (data.address && this.filterAddress) {
          return data.address.toLowerCase().indexOf(this.filterAddress.toLowerCase()) !== -1 || !this.filterAddress.toLowerCase();
        } else {
          return data.address
        };
      });
    }

    if (this.filterEmail) {
      this.temp = this.temp.filter(data => {
        if (data.email && this.filterEmail) {
          return data.email.toLowerCase().indexOf(this.filterEmail.toLowerCase()) !== -1 || !this.filterEmail.toLowerCase();
        } else {
          return data.email
        };
      });
    }

    if (this.filterPosition) {
      this.temp = this.temp.filter(data => {
        if (data.position && this.filterPosition) {
          return data.position.toLowerCase().indexOf(this.filterPosition.toLowerCase()) !== -1 || !this.filterPosition.toLowerCase();
        } else {
          return data.position
        };
      });
    }

    if (this.filterContactType) {
      this.temp = this.fullRows.filter(data => {
        if (data.contact_type && this.filterContactType) {
          return data.contact_type.toLowerCase().indexOf(this.filterContactType.toLowerCase()) !== -1 || !this.filterContactType.toLowerCase();
        }
        else {
          return data.contact_type
        };
      });
    }




    this.count = Object.keys(this.temp).length;
    this.rows = this.temp;
    if (this.count > 0) { this.selected = [this.temp[0]] };
  }

  public toggleFilters() {
    if (this.showFilters) this.showFilters = false;
    else this.showFilters = true;
  }


  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
        <html>
        <head>
        <title>Customer Contact</title>
        <style>
        @page {
          size: A4;
        }

        table {
          border-collapse: collapse;
          border-spacing: 0;
          width: 100%;
          border: 1px solid #ddd !important;
      }

      th, td {
          text-align: left;
          padding: 16px;
      }


      tr:nth-child(even) {
          background-color: #f2f2f2 !important;
          box-shadow: inset 0 0 0 1000px #f2f2f2;
      }

      .fn {
        float: footnote;
      }

      thead {
        box-shadow: inset 0 0 0 1000px #f2f2f2;
      }


      </style>
      </head>
      <body onload="window.print();window.close()">${printContents}</body>
        </html>`
    );
    popupWin.document.close();
  }

  toggleSideBar() {
    if (this.showSidebar == true) {
      this.showSidebar = false;
      this.getPplContacts();
    } else {
      this.showSidebar = true;
    }
  }

  /*** Export... Module ***/
  //Replaces an attribute name for reading comprehension
  renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  //Formats readable header names in the given table
  peopleContacts_generateHeaders(list_peopleContacts_): any {
    const arr = JSON.parse(list_peopleContacts_);
    arr.forEach(obj => {
      this.renameKey(obj, 'first_name', 'Name');
      this.renameKey(obj, 'company_name', 'Company');
      this.renameKey(obj, 'contact_type', 'Type');
      this.renameKey(obj, 'position', 'Position');
      this.renameKey(obj, 'address', 'Address');
      this.renameKey(obj, 'phone', 'Phone');
      this.renameKey(obj, 'mobile', 'Mobile');
      this.renameKey(obj, 'email', 'Email');
      this.renameKey(obj, 'fax', 'Fax');
      this.renameKey(obj, 'abn', 'ABN');
    });
    const updatedJson = JSON.stringify(arr);
    return updatedJson;
  }

  //Filters every column in the table list
  peopleContacts_generateColumnsWithFilter(list_peopleContacts_) {
    const arr = JSON.parse(JSON.stringify(list_peopleContacts_));
    arr.forEach(obj => {
      //Merge First and Last Names in the list
      obj['first_name'] = obj['first_name'] + ' ' + obj['last_name'];

      //remove filtered columns
      if (!this.showName) {
        delete obj['first_name'];
      };
      if (!this.showCompany) {
        delete obj['company_name'];
      };
      if (!this.showContactType) {
        delete obj['contact_type'];
      };
      if (!this.showPosition) {
        delete obj['position'];
      };
      if (!this.showAddress) {
        delete obj['address'];
      };
      if (!this.showPhone) {
        delete obj['phone'];
      };
      if (!this.showMobile) {
        delete obj['mobile'];
      };
      if (!this.showEmail) {
        delete obj['email'];
      };
      if (!this.showFax) {
        delete obj['fax'];
      };
      if (!this.showABN) {
        delete obj['abn'];
      };
      delete obj['id'];
      delete obj['last_name'];
      delete obj['photo'];
      // delete obj['is_accounts'];
      // delete obj['is_operations'];
      // delete obj['is_support'];
    });
    const updatedJson = JSON.stringify(arr);
    return updatedJson;
  }

  //Exports  table to CSV file
  peopleContacts_exportToCSV() {
    if (this.rows.length > 0) {
      let with_header_format, with_column_filter;

      //generate json with filtered column
      with_column_filter = this.peopleContacts_generateColumnsWithFilter(this.rows);

      //generate json with format header
      this.formatted_list = this.peopleContacts_generateHeaders(with_column_filter);

      //export to file
      let current_date = new Date();
      let csv_file = '[Customer]Contacts_' + this.formattedDate(current_date);
      this.exporter.exportToCSV(this.formatted_list, csv_file, 'Customer Portal Contacts');
    }
  };

  //Exports table to PDF file
  peopleContacts_exportToPDF() {
    if (this.rows.length > 0) {
      let with_header_format, with_column_filter;

      //generate json with filtered column
      with_column_filter = this.peopleContacts_generateColumnsWithFilter(this.rows);

      //generate json with format header
      this.formatted_list = this.peopleContacts_generateHeaders(with_column_filter);

      //export to file
      let current_date = new Date();
      let pdf_file = '[Customer]Contacts_' + this.formattedDate(current_date);
      this.exp_pdf.title = 'CONTACTS';
      this.exp_pdf.type = 'pdf';
      this.exp_pdf.list_data = this.formatted_list;
      this.exporter.exportToPDF(this.exp_pdf).subscribe(data => {
        saveAs(data, pdf_file + '.' + this.exp_pdf.type);
      });
    }
  };

  //Exports table to MS Excel file
  peopleContacts_exportToExcel() {
    if (this.rows.length > 0) {
      let with_header_format, with_column_filter;

      //generate json with filtered column
      with_column_filter = this.peopleContacts_generateColumnsWithFilter(this.rows);

      //generate json with format header
      this.formatted_list = this.peopleContacts_generateHeaders(with_column_filter);

      //export to file
      let current_date = new Date();
      let excel_file = '[Customer]Contacts_' + this.formattedDate(current_date);
      this.exp_excel.title = 'CONTACTS';
      this.exp_excel.type = 'xlsx';
      this.exp_excel.list_data = this.formatted_list;
      this.exporter.exportToExcel(this.exp_excel).subscribe(data => {

        saveAs(data, excel_file)
      });
    }
  };

  //Returns a date format in the file name
  public formattedDate(d = new Date) {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${day}/${month}/${year}`;
  }

  public company_contact_id: number = 0;
  public isUpdateCa: boolean = false;
  public current_contact_types = [];
  openAffiliateModal(ct: ContactTypes, ca: ContactAffiliate) {
    if (!ca) {
      this.isUpdateCa = false;
      this.contact_affiliate = new ContactAffiliate();
      this.contact_affiliate.contact_id = ct.id;
      this.affiliateForm.patchValue(this.contact_affiliate);

    } else {
      this.isUpdateCa = true;
      this.contact_affiliate = ca;
      this.contact_affiliate.updated_id = this.user.id;
      this.company_contact_id = ca.id;
      this.current_contact_types = ca.contact_types;

      if (ca.type == 'Customer') {
        this.companies = this.customers;
      } else {
        this.companies = this.suppliers;
      }

      this.updateContactTypesForm(ca.contact_types);
      ca.contact_types.sort((a, b) => a.type_name.localeCompare(b.type_name));
      this.affiliateForm.patchValue(this.contact_affiliate);

    }

    this.modalRef = this.modal.open(this.modalAffiliateContact, { size: 'lg' });

  }



  public modalRef: NgbModalRef;

  openContactModal(contact): void {

    if (!contact) {

      this.isUpdateMode = false;
      this.contact = new PeopleContact();
      this.resetForm();

    } else {

      this.isUpdateMode = true;
      this.contact = contact;

      if (this.contact.photo != 'nopic.png' && this.contact.photo != '') {
        this.picContactExists = true;
        this.oldphoto = this.contact.photo;
      }

      this.contact.updated_id = this.user.id;
      this.contactForm.patchValue(this.contact);
    }

    this.modalRef = this.modal.open(this.modalContact, { size: 'lg' });
  }


  async onSubmitContact(contact) {
    this.spinner = true;    
    this.contact = contact;

    if (this.contactForm.valid) {
      this.contact = contact;

      if (this.photo != '') {
        this.uploadFile(this.prefixPpl, contact.photo);
      }

      if (contact.id) {
        this.updateContact(this.contact);

        console.log('updating contact...');
      }
      else {

        console.log('creating contact entry...');

        await this.pplContactService.createContact(contact).toPromise().then(res => {
          this.alertToaster('success', res['message']);
          if (this.id > 0 || this.id != null) {
            let ca_new = new ContactAffiliate();
            ca_new.company_id = this.id;
            ca_new.contact_id = res['contact_id'];
            ca_new.contact_types = null;
            ca_new.created_id = this.user.id;
            ca_new.dedicated_email = '';
            ca_new.dedicated_phone = '';
            ca_new.dedicated_mobile = '';
            ca_new.type = this.filter;

            this.pplContactService.createContactAffiliate(ca_new).subscribe(result => { });
          }
          this.getPplContacts();
        }).catch(err => {
          this.alertToaster('error', "There was a problem with creating new contact entry. Please try again.");

        }).finally(() => {
          this.getPplContacts();
        });
      }


      if (this.picContactRemove == true) {
        await this.fileService.deleteFile('contacts', this.oldphoto).toPromise().then(result => { console.log(result); })
        this.picContactRemove = false;
      };

      this.closeModal();

    }

  }

  async onSubmitAffiliate(affiliate) {
    this.spinner = true;
    this.contact_affiliate = affiliate;

    if (this.affiliateForm.valid) {
      if (affiliate.id) {
        await this.pplContactService.updateContactAffiliate(this.contact_affiliate).toPromise().then(res => {
          this.alertToaster('success', res['message']);
          this.getPplContacts();
        }).catch(err => {
          this.alertToaster('error', "There was a problem with creating new contact entry. Please try again.");

        }).finally(() => {
          this.getPplContacts();
        });
      } else {
        await this.pplContactService.createContactAffiliate(this.contact_affiliate).toPromise().then(res => {
          this.alertToaster('success', res['message']);
          this.getPplContacts();
        }).catch(err => {
          this.alertToaster('error', "There was a problem with creating new contact entry. Please try again.");

        }).finally(() => {
          this.getPplContacts();
        });
      }
    }

    this.closeModal();
  }


  // getCurrentContactTypes(type, id){
  //   let contact_types = [];
  //   this.pplContactService.getContactTypes(type, id).subscribe(data=>{
  //       contact_types = data["contact_types"];
  //   })
  //   console.log(contact_types,id, type);
  //   return contact_types;
  // }

  public resetForm() {
    const ctrl = (<any>this.contactForm).controls;
    ctrl.first_name.setValue('');
    ctrl.last_name.setValue('');
    ctrl.phone.setValue('');
    ctrl.mobile.setValue('');
    ctrl.email.setValue('');
    ctrl.fax.setValue('');
    ctrl.address.setValue('');
    ctrl.position.setValue('');
    ctrl.abn.setValue('');
    ctrl.photo.setValue('');
  }

  alertToaster(type, message) {
    if (type == 'success') {
      this.toastr.success('', message, {
        timeOut: 5000,
        positionClass: 'toast-top-full-width',
        progressBar: true
      });
    } else {
      this.toastr.error(message, "Internal Server Error 500", {
        timeOut: 5000,
        positionClass: 'toast-top-full-width',
        progressBar: true
      });
    }
  }

  async uploadFile(caller, filename) {
    await this.fileService.uploadRawFile(caller, this.photo, filename)
      .toPromise().then(result => {
        this.contact.photo = result["data"];
      });

  }

  public getFile(event) {
    var pictureFile: ImageResult;
    pictureFile = event;
    this.photo = event;
    this.contactForm.controls.photo.setValue(pictureFile.file.name);
  }

  public removePic(imgCaller: string) {
    if (imgCaller = "contact") {
      this.picContactExists = false;
      this.contactForm.controls.photo.setValue('nopic.png');
      this.picContactRemove = true;
    }
  }

  public closeModal() {
    this.modalRef.close();
    this.photo = '';
    this.picContactExists = false;
    this.picContactRemove = false;
    this.oldphoto = '';
    this.contactForm.reset();
    this.affiliateForm.reset();
    this.initializeForms();
    this.spinner = false;
  }

  async updateContact(contact: PeopleContact) {
    await this.pplContactService.updateContact(contact).toPromise().then(res => {
      this.alertToaster('success', res['message']);
      this.getPplContacts();

    }).catch(response => {
      this.alertToaster('error', "There was a problem with updating the selected contact entry. Please try again.");
    }).finally(() => {
      this.getPplContacts();
    });
    console.log('updated contact');
  }

  async deleteContact(contactId: number) {
    this.spinner = true;
    await this.pplContactService.deleteContact(contactId).toPromise().then(res => {
      this.alertToaster('success', res['message']);
    }).catch(response => {
      this.alertToaster('error', "There was a problem with deleting the selected contact entry. Please try again.");
    }).finally(() => {
      this.getPplContacts();
      this.closeModal();
    });
  }

  openDeleteContactModal(contact: PeopleContact) {

    this.currentContact = contact;

    this.modalRef = this.modal.open(this.modalDeleteContact, { size: 'sm' });
  }

  currentAffiliation: ContactAffiliate;
  openDeleteAffiliationModal(ca: ContactAffiliate) {

    this.currentAffiliation = ca;

    this.modalRef = this.modal.open(this.modalDeleteAffiliation, { size: 'sm' });
  }

  companies = [];
  populateCompany() {
    let selected_contact_type = this.affiliateForm.controls.type.value;
    if (selected_contact_type == 'Customer') {
      this.companies = this.customers;
    } else {
      this.companies = this.suppliers;
    }
  }

  async deleteAffiliation(ca: ContactAffiliate) {
    this.spinner = true;
    await this.pplContactService.deleteContactAffiliate(ca.id, ca.type).toPromise().then(res => {
      this.alertToaster('success', res['message']);
    }).catch(response => {
      this.alertToaster('error', "There was a problem with deleting the selected contact company details. Please try again.");
    }).finally(() => {
      this.getPplContacts();
      this.closeModal();
    });
  }

  checkType(value) {
    let index = this.current_contact_types.findIndex((item) => item.contact_type_id === value)
    if (index == 0) {
      return true;
    } else {
      return false;
    }
  }
  checkEmail(value) {
    let index = this.current_contact_types.findIndex((item) => item.contact_type_id === value);
    let index2 = this.current_contact_types.findIndex((item) => { item.contact_type_id === value && item.email_notification == 1 });

    if (index == 0 && index2 == 0) {
      return true;
    } else {
      return false;
    }
  }
  checkSMS(value) {
    let index = this.current_contact_types.findIndex((item) => item.contact_type_id === value);
    let index2 = this.current_contact_types.findIndex((item) => { item.contact_type_id === value && item.sms_notification == 1 });

    if (index == 0 && index2 == 0) {
      return true;
    } else {
      return false;
    }
  }

  checkValue(ct, event) {
    if (event.target.checked == false) {
      ct.email_notification.setValue(0);
      ct.sms_notification.setValue(0);
    }
  }

  spinner: boolean = false;
}