import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { CustomValidators } from 'ng2-validation';
import { ImageResult } from 'ngx-image2dataurl';
import { Router } from '@angular/router';
import { CookieStorage, LocalStorage, SessionStorage, LocalStorageService } from 'ngx-store';
import { NotificationService } from '../../services/notification.service';

import { FilesService } from './../../services/files.service';
import { UsersService } from './../../services/users.service';
import { DataService } from '../../services/data.service';
import { CustomersService } from '../../services/customers.service';

import { Account, Password } from './../../models/profile.model';
import { User, Url} from './../../models/users.model';
import { Customer } from './../../models/customers.model';

import  *  as  helpData  from  '../../data/help/settings_profile.json';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ CustomersService,  FilesService, UsersService ]
})

export class ProfileComponent implements OnInit {
	@LocalStorage() url: Url;
	@LocalStorage() user: User;
	@LocalStorage() customer: number;


	help:  any;
	public paramsSub : Subscription;
	public user_id : number = 1;
  	public frm_user :FormGroup;
  	public frm_pass :FormGroup;
	public frm_customer :FormGroup;
	public frm_userProfile: FormGroup;  


	public folder_path: string;
	public folder_path_customer: string;
  	public file: string;
  	public pic_exists :boolean;
	public pic_remove :boolean;
	public old_pic: string;

	public passwordNew : FormControl;
	public confirmPassword: FormControl;

	public states:any[];
	public customer_profile: Customer;

	private frm_email: FormGroup;
	private obj_email: string;
	public modal_ref: NgbModalRef;
	spinner: boolean = false;



	constructor(public svc_customer : CustomersService,
                public svc_user : UsersService,
    			public route: ActivatedRoute,
    			private svc_file : FilesService,
    			private fb1 : FormBuilder,
    			private fb2 : FormBuilder,
    			private fb3 : FormBuilder,
	   			private fb4 : FormBuilder,
				public modalService: NgbModal,
				private toaster: NotificationService,
				public router: Router,
				private data: DataService
    			) { 

		this.user_id = this.user.id;
		
        this.pic_exists = false;
	    this.pic_remove = false;
	    this.old_pic = '';
	    this.file = ''; 
	}

	ngOnInit(){
		this.folder_path = this.url.fileStorage + '/users';
		this.folder_path_customer = this.url.fileStorage + '/customers';
		
		this.initializeForms();
		if (this.user.avatar != 'nopic.png' && this.user.avatar != ''){
			this.pic_exists = true;
		}

		this.getUser(this.user['id']);
		this.getCustomer(this.customer);
		this.getStates(); 

		this.data.currentHelp.subscribe(help => this.help = help);

	}

	ngAfterViewInit() {
		this.data.changeHelp(helpData["default"]);
	}

	public getCustomer(id: number) {
		this.svc_customer.getCustomer(id).subscribe(data => {
			this.customer_profile = data["customer"][0];

			//patchValues
			this.frm_customer.patchValue(this.customer_profile);
			//console.log('GET CUSTOMER PROFILE RESULTS', this.customer_profile, this.frm_customer);
			
    	});
	}

	public getUser(id: number) {
		this.svc_user.getUsersbyId(id).subscribe(data => {
			this.user = data["member"][0];
			this.frm_user.patchValue(this.user);
		});
	}

	public getStates(){
		this.svc_customer.getStates().subscribe( data => 
		  this.states = data["states"]
		); 
	  }
	

	public initializeForms(){

		this.passwordNew = new FormControl('', Validators.compose([Validators.required, Validators.minLength(4)]));
        this.confirmPassword = new FormControl('', Validators.compose([Validators.required, CustomValidators.equalTo(this.passwordNew)])); 

	 
	    this.frm_user = this.fb1.group({
	        id				: this.user.id,
	        username		: [null, Validators.compose([Validators.required, Validators.minLength(4)])],
			email			: [null, Validators.compose([Validators.required, Validators.email])],
			avatar			: [''],
			display_name	: [''],
			joined			: [''],
			status			: [''],
			role_id			: [''],
			signature_file	: [''],
			reminder		: [''],
			remember		: [''],
			subrole_id		: ['']
		});
		
	    this.frm_pass = this.fb2.group({
		    id				: this.user.id,
	        old_password	: '',
	        confirm_password: this.confirmPassword,
	        new_password	: this.passwordNew,
	    });

	    this.frm_customer = this.fb3.group({
			id                           : this.customer,
			user_id                      : [''],
			company_name                 : ['', Validators.required],
			contact_name                 : ['', Validators.required],
			phone_no                     : ['', Validators.required],
			mobile_no                    : [''],
			fax_no                       : [''],
			billing_address              : ['', Validators.required],
			city                         : ['', Validators.required],
			state                        : ['', Validators.required],
			postal_code                  : ['', Validators.required],
			country_region               : ['', Validators.required],
			first_email                  : [null, Validators.required],
			second_email                 : [null, Validators.required],
			abn			                 : [{value:null, disabled:true}],
			acn			                 : [{value:null, disabled:true}],
		});

		this.frm_email = this.fb4.group({
			email : ['', Validators.compose([Validators.required, Validators.email])]
		});

	}	

	async onSubmitCustomer(customer: Customer){
		this.spinner = true;
		if (this.frm_customer.valid) {
		//this.customer_profile = customer;
		//console.log(customer, 'form values')
	 
		await this.svc_customer.updateCustomerProfile(customer).toPromise().then(result=>{
			this.promptToaster(result["error"], result["message"], 'update_customer');
			this.customer_profile = result['customer'];
		})
		
	  }
	  this.spinner = false;
	}

  	public onSubmitUser(user: User){
  		if (this.frm_user.valid) {
	      this.user = user;
		  if(this.file != ''){
	        this.createFile(user);
	      }else{
	          this.updateUser(this.user);
	      }
	      
	      //handling image
	      if(this.pic_remove == true){
	        this.svc_file.deleteFile('users',this.old_pic).subscribe(result=>{})
	        
	        //resets value of file variables as the form is in modal view
	        this.pic_remove = false;
	        this.file ='';
	        this.old_pic = '';
	        this.pic_exists = false;
	      };
		}
	
	}

  	async updateUser(user:Account) {
		this.spinner = true;
  		await this.svc_user.updateAccount(user).toPromise().then(member => {
			this.promptToaster(member["status"], member["message"], '');
		});
		this.spinner = false;
	}

	async updatePassword(password: Password) {
		this.spinner = true;
		//console.log('password_data',password);
		await this.svc_user.updatePassword(password).toPromise().then(member => {
			this.promptToaster(member["status"], member["message"], '');
		});
		this.spinner = false;
	}

  	public createFile(user : User) {
		this.svc_file.uploadImageFile('users',this.file).toPromise().then(file =>{
			this.frm_user.controls.avatar.setValue(file['data']);
		this.user.avatar = file['data'];
		
	   if (this.user.avatar != 'nopic.png' && this.user.avatar != ''){
		this.pic_exists = true;
		}

	   if(user.id){
		this.updateUser(this.user);
		}
	  });
    }

	public getFile(event){
		var pictureFile : ImageResult;
			pictureFile = event;

		this.file = event;
		this.frm_user.controls.avatar.setValue(pictureFile.file.name);
  	}

  	public removePic(){
		this.pic_exists  = false;
		this.old_pic = this.frm_user.controls.avatar.value;
		this.frm_user.controls.avatar.setValue('nopic.png');
		this.pic_remove = true;
		this.file ='';
	 }
	 
	private promptToaster(status, msg, caller){
		if(caller =='update_customer'){
			if (status == false) {
				this.toaster.showSuccess('', msg);
			}
			else {
				this.toaster.showError('', msg);
			}
		}else{
			if (status == true) {
				this.toaster.showSuccess('', msg);
			}
			else {
				this.toaster.showError('', msg);
			}
		}
	}

	public openEmailModal(modal_email, obj_email) {
    
		this.modal_ref = this.modalService.open(modal_email, { container: '.app',size: 'sm' });
		this.obj_email = obj_email;      
	  }
	
	  public addEmail(email){
		if(this.frm_email.valid){
		   var first_email = this.frm_customer.controls.first_email.value;
		   var second_email = this.frm_customer.controls.second_email.value;
	
		   if(this.obj_email == 'first_email'){
			 if (first_email != null && first_email != ''){
				first_email += ',' + this.frm_email.controls.email.value;
			 }else{
				first_email = this.frm_email.controls.email.value;
			 }
			this.frm_customer.controls.first_email.setValue(first_email);
		   }
	
	
		   if(this.obj_email == 'second_email'){
			 if (second_email != null && second_email != ''){
				second_email += ',' + this.frm_email.controls.email.value;
			 }else{
				second_email = this.frm_email.controls.email.value;
			 }
			this.frm_customer.controls.second_email.setValue(second_email);
		   }
	
		}
	
		this.frm_email.reset();
		this.obj_email = null;
		this.modal_ref.close();
	  }
	
	   public closeEmailModal(){
		 this.modal_ref.close();
	   }
	
	  public eraseEmail(obj_email){
		switch (obj_email)
		{
		  case 'first_email': {
		  this.frm_customer.controls.first_email.setValue(null);break;
		  };
		  case 'second_email': {
		  this.frm_customer.controls.second_email.setValue(null);break;
		  };
		}
	  }

}

/***
 * UPDATE HISTORY:
 * Apr 22, 2020 KA: Added abn, acn, country region and company logo.
 */
  