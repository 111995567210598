import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { PipesModule } from '../theme/pipes/pipes.module';
import { FileUploaderModule } from './../pages/controls/file-uploader/file-uploader.module';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { ImageUploaderModule } from './../pages/controls/image-uploader/image-uploader.module';
import { SignaturePadModule } from 'angular2-signaturepad';
import { NgxDaterangepickerMd,DaterangepickerComponent } from 'ngx-daterangepicker-material';


@NgModule({
    imports: [
        CommonModule,
        NgxLoadingModule,   
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        PipesModule,
        FileUploaderModule,
        NgxDatatableModule,
        ImageUploaderModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.threeBounce,
            backdropBackgroundColour: 'rgba(0, 0, 0, 0)',
            backdropBorderRadius: '4px',
            primaryColour: '#666666',
            secondaryColour: '#666666',
            tertiaryColour: '#666666'
          }),
          SignaturePadModule,
          NgxDaterangepickerMd.forRoot(),

     ],
    declarations: [
    ],
    exports: [
    ],
    entryComponents: [DaterangepickerComponent]
})
export class SharedModule {}