import { Injectable } from '@angular/core';
import { Job, JobFile, JobCustomerFile,  Manifest } from './../models/jobs.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Url } from './../models/users.model';
import { LocalStorage } from 'ngx-store';
import * as moment from "moment";

@Injectable()
export class JobsService {
    @LocalStorage() url: Url;

  	private headers = new Headers({ 'Content-Type': 'application/json' });

    constructor(public http:HttpClient) {
    }

    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

	getJobDetails(id: number): Observable<Job[]> { 
		return this.http.get<Job[]>(this.url.rest  + '/tms/getJobDetails/' + id, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)     
            )
    }
    
    createMember(member:Job): Observable<Job> {	
        return this.http.post<Job>(this.url.rest  + '/tms/createMember', member , this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    createJobFile(jobFile) {
        console.log(jobFile);
        return this.http.post<JobFile>(this.url.rest  + '/tms/createJobFile', jobFile, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }
    deleteJobFile(jobFile) {
        console.log(jobFile);
        return this.http.delete(this.url.rest  + '/tms/deleteJobFile/' + jobFile)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    createJobCustomerFile(cf) {
        return this.http.post<JobCustomerFile>(this.url.rest  + '/tms/createJobCustomerFile', cf, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    updateJobCustomerFile(cf) {
        return this.http.put<JobCustomerFile>(this.url.rest  + '/tms/updateJobCustomerFile', cf, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    deleteJobCustomerFile(cf) {
        return this.http.delete(this.url.rest  + '/tms/deleteJobCustomerFile/' + cf)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    updateMember(member:Job): Observable<Job> {
        console.log(member);
        return this.http.put<Job>(this.url.rest  + '/tms/updateMember', member, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    deleteMember(id: number) {
        return this.http.delete(this.url.rest  + "/tms/deleteMember/" + id);
    }   

    getJobsByDriverAssignments(id:number): Observable<any[]> { 
        return this.http.get<any[]>(this.url.rest  + '/tms/getJobsByDriverAssigments/' + id, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }	

    getLegRoutes(points): Observable<any> {
        return this.http.post<any>(this.url.rest  + '/tms/getLegRoutes', points, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }
       
    private extractData(res: Response) {
    	let body = res.json();
    	console.log(body);
	    if(body)
            return body || {};
        else
            return null;
    }

    createFile(image_file : any): Observable<File>
    {	
        return this.http.post<File>(this.url.rest  + '/tms/upload/file', image_file, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    deleteFile(fileName : string)
    {
    	return this.http.delete(this.url.rest   + '/tms/delete/file' + fileName)
                        
    }

    getMembersbyId(id: number): Observable<Job[]> { 
        return this.http.get<Job[]>(this.url.rest  + '/tms/getMemberById/' + id, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )    }


    //// Manifests Services
    getManifestList(): Observable<Manifest[]> {
        return this.http.get<Manifest[]>(this.url.rest  + '/tms/getManifestList', this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )    }

    getManifestDetails(id: number): Observable<Manifest[]> {
        return this.http.get<Manifest[]>(this.url.rest  + '/tms/getManifestDetails/' + id, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )    }

    updateManifest(manifest: Manifest): Observable<Manifest> {
        return this.http.put<Manifest>(this.url.rest  + '/tms/updateManifest', manifest, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )    }

    createLogsheetDriverJobs(logsheet_jobs : any): Observable<any[]>
    {    
        return this.http.post<any[]>(this.url.rest  + '/tms/createLogsheetDriverJobs', logsheet_jobs, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )    }

    getJobByControlNo(control_no: string): Observable<any[]> {
        return this.http.get<any[]>(this.url.rest  + '/tms/getJobByControlNo/' + control_no, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )    
    }

    // job notes
    getNotes(id: number): Observable<any[]> {
        return this.http.get<any[]>(this.url.rest  + '/tms/getJobNotes/' + id, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )    
    }

    createNote(note: any): Observable<any> {
        return this.http.post<any>(this.url.rest  + '/tms/createJobNote', note, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )    }

    updateNote(note: any): Observable<any> {
        return this.http.post<any>(this.url.rest  + '/tms/updateJobNote', note, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )    }

    deleteNote(id: number): Observable<any[]> {
        return this.http.delete<any[]>(this.url.rest  + '/tms/deleteJobNote/' + id, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )    
    }

    // end job notes\\\\
    getChangeoverJobPoints(postData: any): Observable<any[]> {
        return this.http.post<any[]>(this.url.rest  + '/api/getPoints', postData, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )    
    }

    addChangeover(postData: any): Observable<any[]> {
        return this.http.post<any[]>(this.url.rest  + '/api/addChangeover', postData, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )    
    }

    cancelJob(job_id: number): Observable<any[]> {
        return this.http.post<any[]>(this.url.rest  + '/tms/cancelJob/' + job_id, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )    
    }

    reactivateJob(job_id: number): Observable<any[]> {
        return this.http.post<any[]>(this.url.rest  + '/tms/reactivateJob/' + job_id, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )    
    }

    getJobs(customer_id, statusFilter = '', dateFilter = '', start = '', end = ''): Observable<Job[]> { 
        var param = '';
        if (statusFilter) {
            param = '/' + customer_id + '/' + statusFilter;
            if (dateFilter) {
                param = param + '/' + dateFilter;
                if (dateFilter == 'range') {
                    if (moment(end).isSameOrAfter(moment(start))) {
                        param = param + '/' + start + '/' + end; 
                    }
                } 
            }

        }
        console.log("start", start, "end", end);
        console.log('PROCESSING....', param);
        return this.http.get<Job[]>(this.url.rest  + '/customers/getJobs' + param, this.httpOptions)
        .pipe(
          retry(1),
          catchError(this.handleError)
        ) 
      }

    bulkMarkForInvoice(jobs, user_id): Observable<any[]> {
        return this.http.post<any[]>(this.url.rest  + '/tms/bulkMarkForInvoice/' + user_id, jobs,  this.httpOptions)
            .pipe(
            retry(1),
            catchError(this.handleError)
        )    
    }

    getCompletedJobs(customer_id: number): Observable<Job[]> { 
        return this.http.get<Job[]>(this.url.rest  + '/customers/getCompletedJobs/' + customer_id, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }


    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }

}