import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef  } from '@angular/core';import { XeroService } from '../xero.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-xero-connection-button',
  templateUrl: './connection-button.component.html',
  styleUrls: ['./connection-button.component.scss']
})
export class ConnectionButtonComponent implements OnInit {

  public modalRef: NgbModalRef;
  @ViewChild('modalConnectXero', { static: false }) modalConnectXero: TemplateRef<any>;
  @ViewChild('modalDisconnectXero', { static: false }) modalDisconnectXero: TemplateRef<any>;
  constructor(
    private xero: XeroService,private modal: NgbModal,private toastr: ToastrService
  ) { }

  ngOnInit(): void { }

  /**
   * Connect user to Xero.
   */
  public xeroConnect() {
    this.modalRef.close();
    this.xero.connect();
  }

   //Connect Xero Modal
 openConnectXeroModal() {
  this.modalRef = this.modal.open(this.modalConnectXero, { size: 'md'});
}

  /**
   * Disconnect user from Xero.
   */
  public xeroDisconnect() {
    this.xero.disconnect();
    this.modalRef.close();
  }

   // Disconnect Xero Modal
 openDisconnectXeroModal() {
  this.modalRef = this.modal.open(this.modalDisconnectXero, { size: 'md'});
}

//Closing Modal
public closeModal() {
  this.modalRef.close();
}

  /**
   * Get connection, whether user is connected to Xero or not.
   */
  public get isConnected(): boolean {
    return this.xero.isConnected;
  }
}
;