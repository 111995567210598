
import { Component, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { Options, ImageResult } from 'ngx-image2dataurl';
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-image-uploader',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  exportAs: 'app-image-uploader',
})

export class ImageUploaderComponent {

    @Output() passImage : EventEmitter<any>= new EventEmitter<any>();

    public error:any;
    
    public src: string = null;
    public isPdf : boolean = false;
    
    options: Options = {
      /*resize: {
        maxHeight: 140,
        maxWidth: 140
      },*/
      allowedExtensions: ['JPG', 'PNG', 'GIF', 'PDF', 'JPEG' ]
    };

    constructor(private toastr: ToastrService)
    {
      
    }

    selected(imageResult: ImageResult) {
       this.isPdf = false;

       if (imageResult.file.type == 'application/pdf')
       {
         this.isPdf = true;
       }

      if (!imageResult.error){

        if (this.isPdf){
          this.src = imageResult.dataURL;
        }else{
          this.src = imageResult.resized
                  && imageResult.resized.dataURL
                  || imageResult.dataURL;
        };

        this.passImage.emit(imageResult);

        this.toastr.success('The image is successfully selected', 'Image File Selected', {
            timeOut: 5000,
            positionClass: 'toast-top-full-width',
            progressBar: true
          });
        //  this.getMember(this.user.id);  
        }
        else {
          this.toastr.error( imageResult.error, 'Image Select Error', {
            timeOut: 5000,
            positionClass: 'toast-top-full-width',
            progressBar: true
          });

          this.passImage.emit(null);

      } 
      

    }

}
