import { ModuleWithProviders } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/lib/core/authentication/auth.guard';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { PagesComponent } from './pages/pages.component';
import { JobNotificationsComponent } from './pages/jobnotifications/jobnotifications.component';
import { SearchComponent } from './pages/search/search.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { RatesComponent } from './pages/rates/rates.component';
import { MapTrackerComponent } from './pages/maptracker/maptracker.component';
import { SupportComponent } from './pages/support/support.component';
import { PeopleContactsComponent } from './pages/peoplecontacts/peoplecontacts.component';


export const routes: Routes = [
  {
    path: '', 
    component: PagesComponent,
    children:[
      { path: '', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' }, canLoad: [AuthGuard], canActivate: [AuthGuard]  },  
      { path: 'bookings',loadChildren: () => import('./pages/bookings/bookings.module').then(m => m.BookingsModule), data: { breadcrumb: 'Bookings' }},        
      { path: 'compliance',loadChildren: () => import('./pages/compliance/compliance.module').then(m => m.ComplianceModule), data: { breadcrumb: 'Compliance' }}, 
      { path: 'activities', component: JobNotificationsComponent, data: { breadcrumb: 'Activities' } },
      { path: 'search', component: SearchComponent, data: { breadcrumb: 'Search' } },
      { path: 'rates', component: RatesComponent, data: { breadcrumb: 'Rates' } },
      { path: 'profile', component: ProfileComponent, data: { breadcrumb: 'Profile' } },
      { path: 'support', component: SupportComponent, data: { breadcrumb: 'Support' } },
      { path: 'maptracker', component: MapTrackerComponent, data: { breadcrumb: 'Map Tracker' } },
      { path: 'contacts', component: PeopleContactsComponent, data: { breadcrumb: 'Contacts' } },

     ]
  },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: 'logout', loadChildren: () => import('./pages/logout/logout.module').then(m => m.LogoutModule) },
 // { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
  { path: '**', component: NotFoundComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
  //  preloadingStrategy: PreloadAllModules,  // <- comment this line for enable lazy load
  // useHash: true
});