import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LocalStorage } from 'ngx-store';
import { User, Url } from '../../../models/users.model';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserMenuComponent implements OnInit {

  @LocalStorage() user: User;
  @LocalStorage() url: Url;

  admin: any;
  public folderPath: string;

  constructor() { }

  ngOnInit() {
    this.admin = this.user;
    this.folderPath = this.url.fileStorage + '/users/';
  }

  public formatDate(inDate: string) {
    let formatedDate: Date;
    formatedDate = new Date(inDate.replace(/-/g, "/"));
    return formatedDate;
  }

}
