import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'BookingSearchPipe', pure: false })
export class BookingSearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(booking => {
        if (booking.reference_no) {
          return booking.reference_no.search(searchText) !== -1;
        }
      });
    }
  }
}