import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorage, LocalStorageService } from 'ngx-store';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { PasswordGrantCreds } from 'src/lib/models/oauth';
import { environment } from '../../environments/environment';
import { AuthResponse, loggedOutUri } from '../../lib/core/authentication/auth.config';
import { AuthService } from '../../lib/core/authentication/auth.service';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { Profile, ThemeSetting, Url, User } from '../models/users.model';


const helper = new JwtHelperService();

@Injectable()
export class LoginService {
  @LocalStorage() companyCode: string;
  @LocalStorage() token: string;
  @LocalStorage() user: User;
  @LocalStorage() profile: Profile;
  @LocalStorage() themeSettings: ThemeSetting;
  @LocalStorage() companyName: string;
  @LocalStorage() customer: number;
  @LocalStorage() url: Url;

  public settings: Settings;

  constructor(
    private http:                HttpClient,
    public  localStorageService: LocalStorageService,
    public  appSettings:         AppSettings,
    private authenticator:       AuthService,
    private router:              Router
  ) {
    this.settings = this.appSettings.settings;
  }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getCompanyDetails(companyCode: string): Observable<Object> {
    const empressData = {
      company: companyCode,
      ver: 30
    }
    return this.http.post(environment.restApiForClientData, empressData, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getAuthClientId(url: string): Observable<{ id: string }> {
    return this.http.get<{ id: string }>(url + '/customers/getAuthClientId', this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  login(clientCreds: PasswordGrantCreds, companyName: string, companyCode: string, username: string, password: string) {
    console.info(`Client attempts to log into "${companyCode}".`);

    return this.authenticator
      .init(clientCreds)
      .authenticate(username, password)
      .pipe(
        tap((response: AuthResponse) => {
          this.companyName = companyName;
          this.user = response.admin_user;
          this.companyCode = companyCode;
          this.profile = response.admin_profile;
          this.themeSettings = response.theme_settings;
          this.customer =   response.customer;

        }),
        catchError(this.handleError)
      );
  }

  logOut() {
    this.settings.theme.skin = 'brown';

    this.localStorageService.clear('prefix');
    this.authenticator.revokeToken()
      .subscribe(
        () => this.router.navigate([ loggedOutUri ]),
        () => alert('Something went wrong. Please try again.')
      );
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      console.log(error)
    }
    console.log(errorMessage);

    return throwError(errorMessage);
  }
}