import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { CustomValidators } from 'ng2-validation';
import { ImageResult } from 'ngx-image2dataurl';
import { Router } from '@angular/router';
import { CookieStorage, LocalStorage, SessionStorage, LocalStorageService } from 'ngx-store';
import { NotificationService } from '../../services/notification.service';

import { FilesService } from './../../services/files.service';
import { UsersService } from './../../services/users.service';
import { DataService } from '../../services/data.service';
import { CustomersService } from '../../services/customers.service';
import { RatesService } from '../../services/rates.service';

import { DefaultRate } from './../../models/rates.model';
import { User, Url} from './../../models/users.model';
import { Customer } from './../../models/customers.model';

import  *  as  helpData  from  '../../data/help/rates.json';


@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ CustomersService,  FilesService, UsersService,RatesService ]
})

export class RatesComponent implements OnInit {
	@LocalStorage() url: Url;
	@LocalStorage() user: User;
	@LocalStorage() customer: number;


    help:  any;
    customer2: number;
    public paramsSub : Subscription;
    public searchText: string;
    public searchText2: string;
    public customer_id : number;
    public customers : Customer;
    public p1 :any;
    public p2 :any;
    //public frmLogin : FormGroup;
    public modalRef: NgbModalRef;
    public modalRef2: NgbModalRef;

    public folderPath: string;
   
    
    public frmCustomer:FormGroup;
    public states:any[];
  
    public picture: string;
    public fileLogo : string;
    public picCustomerExists :boolean;
    public picCustomerRemove :boolean;
    public oldpicture: string;
    public oldfileLogo : string;
  
    customerDefaultRate : DefaultRate;
    frmCustomerDefaultRate : FormGroup;
    public isDataLoaded: boolean = false;
    public isUpdateMode: boolean = false;
  
    public frmEmail: FormGroup;
    public objEmail: string;
  
    public customerInvoiceDefaults : any[];
    public service_types:any[];
    public job_types:any[];
    public charging_methods:any[];
    public payment_methods:any[];
    public invoice_banks: any[];
    loadingIndicator: boolean = false;
    spinner: boolean = false;


    constructor(public rates_customer : RatesService,
                public svc_user : UsersService,
    			      public route: ActivatedRoute,
    			      private svc_file : FilesService,
    			      private fb : FormBuilder,
    			      private fb3 : FormBuilder,
			        	public modalService: NgbModal,
				        private toaster: NotificationService,
				        public router: Router,
                private data: DataService,
                private http: HttpClient
    			) {
                  this.customer_id =  this.customer
                  //console.log('wow',this.customer)
                }
	ngOnInit(){
        this.data.currentHelp.subscribe(help => this.help = help);

        //console.log("CUSTOMER_ID",this.customer_id)
        this.initializeForms();
        this.getStates(); 
        this.getServiceTypes();
        this.getPaymentMethods();
        this.getChargingMethods();
        this.getJobTypes();
        this.getInvoiceBanks();
        this.getCustomer(this.customer_id);
        this.getCustomerDefaultRate();


        this.folderPath = this.url.rest + '/customers/';

  }
  
  ngAfterViewInit() {
    setTimeout(() => { 	this.data.changeHelp(helpData["default"]);  });
  }


    public initializeForms(){
        const intRegex = '^[\s]?[0-9]{1,12}$'; 
        const int1Regex = '^[\s]?[0-9]{1,10}$'; 
        const email =  "(([a-zA-Z\-0-9\.]+@)([a-zA-Z\-0-9\.]+)[,]*)+"
         this.frmCustomer = this.fb.group({
          id                           : null,
          user_id                      : [''],
          company_name                 : [''],
          contact_name                 : [''],
          job_type_id                  : [''],
          abn                          : ['',Validators.pattern(intRegex)],
          acn                          : ['',Validators.pattern(int1Regex)],
          phone_no                     : [''],
          mobile_no                    : [''],
          fax_no                       : [''],
          billing_address              : [''],
          city                         : [''],
          state                        : [''],
          postal_code                  : ['None'],
          country_region               : [''],
          first_email                  : ['',Validators.pattern(email)],
          second_email                 : ['',Validators.pattern(email)],
          file_logo                    : ['nopic.png'],
          //invoice_note_id              : [''],
          invoice_bank_id               : [''],
          payment_term_id              : [''],
          days_due                     : ['0'],
          default_fuel_levy            : [''],
          default_service_fee          : [''],
          default_job_type_id          : [''],
          default_charging_method_id   : [''],
          default_service_type_id      : [''],
          default_contact_name         : [''],
          reference_no_prefix          : [''],
          bank_name                    : [''],
          bsb                          : [''],
          acct_no                      : [''],
          acct_name                    : ['']
          });
  
  
        this.frmCustomerDefaultRate = this.fb3.group({
          id                       : [''],
          service_type_id          : [0],
          charging_method_id       : [0],
          customer_id              : this.customer_id,
          rate                     : [0],
          service_type_category_id : ['0'],
          tail_lift_per_pallet     : ['0'],
          hand_unload_per_pallet   : ['0'],
          extra_pickup_delivery    : ['0'],
          dg_packed                : ['0'],
          dg_bulk                  : ['0'],
          bdbl_split_per_site      : ['0'],
          hr_waiting_time          : ['0'],
          semi_waiting_time        : ['0'],
          bdbl_waiting_time        : ['0'],
          hr_demurrage_per_hour    : ['0'],
          semi_demurrage_per_hour  : ['0'],
          bdbl_demurrage_per_hour  : ['0'],
          futile_per_pallet        : ['0'],
          extra_crew_per_pallet    : ['0'],
          residential_per_site     : ['0'],
          after_hours_per_site     : ['0'],
          hr_overnight_holding     : ['0'],
          semi_overnight_holding   : ['0'],
          bdbl_overnight_holding   : ['0'],
          pallet_max_width         : ['0'],
          pallet_max_length        : ['0'],
          pallet_max_height        : ['0'],
          pallet_max_weight        : ['0'],
          hr_default_load_rate     : ['0'],
          semi_default_load_rate   : ['0'],
          bdbl_default_load_rate   : ['0'],
          fuel_levy                : ['0'],
          service_fee              : ['0'],
          payment_term_id          : [''],
          job_type_id              : [''],
          //default_service_type_id  : [''],
          invoice_bank_id          : ['']
  
        });
        //console.log('TY',this.frmCustomerDefaultRate)
  
        this.frmEmail = this.fb.group({
          email : ['', Validators.compose([Validators.required, CustomValidators.email])]
        })
      }
  
      isEmail() {
        const frmCtrl = (<any>this.frmEmail);
    
        if (frmCtrl.controls.email.valid)
          return true;
        else return false;
      }
  
      

      async getCustomer(id: number) {
          await this.rates_customer.getCustomer(id).toPromise().then(data => {
               this.customers = data["customer"][0];
  
              this.frmCustomer.patchValue(this.customers);
  
  
             if(this.customers.default_service_type_id != null){
                this.getServiceTypeCategory(this.customers.default_service_type_id);
              } 
  
           });
      }

      async getCustomerDefaultRate(){
        await this.rates_customer.getDefaultRatesByCustomerId(this.customer_id).toPromise().then(data=>{
          this.customerDefaultRate = data['customer_rates'][0];
          //console.log("wow",this.customerDefaultRate)
          this.isDataLoaded = true;
  
          if (this.customerDefaultRate)
          {        
            this.frmCustomerDefaultRate.patchValue(this.customerDefaultRate);
            this.isUpdateMode = true;
    
          }else{
            //patching invoice and payment related fields in customer in frmCustomerDefaultRate
            this.frmCustomerDefaultRate.controls.charging_method_id.setValue(this.customers.default_charging_method_id);
            this.frmCustomerDefaultRate.controls.service_type_id.setValue(this.customers.default_service_type_id);
          };
  
          //patching invoice and payment related fields in customer in frmCustomerDefaultRate
          this.frmCustomerDefaultRate.controls.payment_term_id.setValue(this.customers.payment_term_id);
          this.frmCustomerDefaultRate.controls.job_type_id.setValue(this.customers.job_type_id);
          this.frmCustomerDefaultRate.controls.invoice_bank_id.setValue(this.customers.invoice_bank_id);
          
  
        });
        setTimeout(() => { this.loadingIndicator = false; }, 500);
      }
  
      async getServiceTypes(){
        await this.rates_customer.getServiceTypes().toPromise().then( data =>{
          this.service_types = data["service_types"]
        });
      }
  
      async getPaymentMethods(){
         await this.rates_customer.getPaymentMethods().toPromise().then( data => 
          this.payment_methods = data["payment_methods"]
        );
      }
  
      async getChargingMethods(){
        await this.rates_customer.getChargingMethods().toPromise().then( data => 
          this.charging_methods = data["charging_methods"]
        );
      }
  
      async getJobTypes(){
        await this.rates_customer.getJobTypes().toPromise().then( data => 
          this.job_types = data["job_types"]
        );
      }
  
      async getInvoiceBanks(){
        await this.rates_customer.getInvoiceBanks().toPromise().then( data => 
          this.invoice_banks = data["invoice_banks"]
        );
      }
  
 
  
      public openModal(modalContent) {
        this.modalRef = this.modalService.open(modalContent, { container: '.app' });
      }
  
      async closeModal(event){
        this.modalRef.close();

        if(event)
        {
          this.customers.user_id = event;
          await this.rates_customer.updateCustomer(this.customers).toPromise().then(data=>{
            this.getCustomer(this.customer_id);  
          });
        }else{
          this.getCustomer(this.customer_id);  
        };
      }
  
      public openEditModal(modalContent) {
        
        //this.frmCustomer.patchValue(this.customer);
        this.modalRef2 = this.modalService.open(modalContent, { container: '.app' });
      }
  
      public closeCustomerModals(){
          this.modalRef2.close();
          this.getCustomer(this.customer_id);
          this.frmCustomer.reset();
      }
  
      async getStates(){
        await this.rates_customer.getStates().toPromise().then( data => 
          this.states = data["states"]
          ); 
      }
  
      async submitCustomer(){
        this.customers = this.frmCustomer.value;
         
  
            await this.rates_customer.updateCustomer(this.customers).toPromise().then(result=>{
              this.toasterAlert(result["status"], result["message"]);
              this.getCustomer(this.customer_id);
            });
         ;
        this.closeCustomerModals();
      }
  
  
  
    async onSubmit(customerDefaultRate) {
      this.spinner = true;
      //console.log('WAZZUP',customerDefaultRate)
  
        //setting service_category_type_id
        this.customerDefaultRate = customerDefaultRate;
        this.getServiceTypeCategory(customerDefaultRate.service_type_id);
  
        if(this.isUpdateMode){
          await this.rates_customer.updateDefaultRate(this.customerDefaultRate).toPromise().then(data=>{
            this.toasterAlert(data['status'], data["message"] );
          });
        }else{
          await this.rates_customer.createDefaultRate(this.customerDefaultRate).toPromise().then(data=>{
            this.toasterAlert(data['status'], data["message"] );
          });
        }
  
        //updating customer invoice payment fields
        this.customerInvoiceDefaults = [{
          'id'                         : this.customerDefaultRate.customer_id,
          'default_fuel_levy'          : this.customerDefaultRate.fuel_levy,
          'default_service_fee'        : this.customerDefaultRate.service_fee,
          'payment_term_id'            : customerDefaultRate.payment_term_id,
          'default_charging_method_id' : this.customerDefaultRate.charging_method_id,
          'job_type_id'                : customerDefaultRate.job_type_id,
          'default_service_type_id'    : this.customerDefaultRate.service_type_id,
          'invoice_bank_id'            : customerDefaultRate.invoice_bank_id,
          }];
        
          //console.log('wew',this.customerInvoiceDefaults)
        await this.rates_customer.updateCustomerInvoicePayment(this.customerInvoiceDefaults[0]).toPromise().then(data=>{
          this.getCustomer(this.customer_id);
          this.getCustomerDefaultRate();
        });
        
        this.frmCustomerDefaultRate.reset();
        this.initializeForms();
        this.spinner = false;
    }
  

  
    public toasterAlert(status, msg)
    {
      if (status == true) { 
            this.toaster.showSuccess('', msg);
          }
          else { 
              this.toaster.showError('', msg);
          };
    }
  
  
    getServiceTypeCategory(service_type_id){
        let category = this.service_types.filter(data =>
              { 
                if (data.id == service_type_id)
                  return data.service_category_id 
              });
        
        if (category.length > 0)
        {
          if(this.customerDefaultRate){ 
            this.customerDefaultRate.service_type_category_id = category[0].service_category_id;
          }
        }
    }
  
    public formatDate(inDate: string) {
      let formatedDate: Date;
      
      if(inDate){
        if(inDate !== null && inDate !== '' && inDate !== '0000-00-00' && inDate !== '0000-00-00 00:00:00'){
          if(inDate.toString().indexOf('-') >0 )
            {formatedDate = new Date(inDate.toString().replace(/-/g, "/"));}
        }
      }
      
      return formatedDate;
    }

}

  