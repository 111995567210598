import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'MemberSearchPipe', pure: false })
export class MemberSearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(member => {
        if (member.display_name) {
          return member.display_name.search(searchText) !== -1;
        }
        else{
          return member.username.search(searchText) !== -1;
        }
      });
    }
  }
}