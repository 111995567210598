/* tslint:disable */
import { Component, ViewEncapsulation} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MapData } from '../../models/maptracker.model';
import { MapTrackerService } from '../../services/maptracker.service';
import { DataService } from '../../services/data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Url } from '../../models/users.model';
import  *  as  helpData  from  '../../data/help/tracker.json';
import { LocalStorage } from 'ngx-store';


@Component({
	selector: 'app-tracker',
	templateUrl: './maptracker.component.html',
	styleUrls:['../datatable.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [MapTrackerService]
})
export class MapTrackerComponent {
	@LocalStorage() url: Url;
	@LocalStorage() customer: number;

	public mapMarkers: any[];
	public mapData: MapData[];
	public positions: any[];
	public fullPositions: any[];
	public tablePositions: any[];
	public mapDataLength: number;
	public activities: any[];
	public temp: any[];

	public centerLat: number =  -33.395818;
	public centerLng: number = 147.99444100000005;
	public mapZoom: number = 4;
	totalCost: any;
	fleetCost: any;
	wageCost: any;
	expiries: any;
	expired: any;
	expiring_count: number = 0;
	expired_count: number = 0;
	public fleet_assign_count: number = 0;
	public mapSearchTerm: string;
	help: any;
	loadingIndicator: boolean = true;
	loadingIndicator2: boolean = true;

	marker = {
		display: true,
		lat: null,
		lng: null,
		driver: null,
		description: null,
		fleet: null
	  };
	mydata = [];
	mydata_temp = [];

	constructor(private mapsService: MapTrackerService, private data: DataService, private http: HttpClient,
		private firestore: AngularFirestore) {

		this.mapMarkers = [
			{ lat: -38.0304739, lng: 145.1826437 },
			{ lat: -27.5508191, lng: 152.9860879 },
			{ lat: -33.8015275, lng: 150.8146566 },
			{ lat: -34.9998825, lng: 138.330979 }
		];
	}

	ngOnInit() {
		this.loadingIndicator = true;
		this.showMarkers();
		
		this.data.currentHelp.subscribe(help => this.help = help);
	}

	ngAfterViewInit() {
		setTimeout(() => { 	this.data.changeHelp(helpData["default"]);  });
	}

	newMessage() {
		console.log('test');
	}

	showMarkers() {
		let driverRef: any;
		let queryRef: any;
		this.positions = [];
		this.mapsService.getCustomerMaptracker(this.customer)
		.toPromise()
        .then(async data => {
			for (let index = 0; index < data['active_drivers'].length; index++) {
				driverRef = this.firestore.collection(data['active_drivers'][index].name).ref;
				queryRef = driverRef.where('company_code', '==', JSON.parse(localStorage.getItem('ngx_companyCode').toLowerCase())).orderBy('timestamp', 'desc').limit(1)
				.onSnapshot(async (queryRef) => {
					console.log('index', index);
					this.mydata[index] = (await queryRef).docs.map(doc => doc.data());
					if (this.mydata[index] != '') {
						this.mydata[index][0]['description'] = data['active_drivers'][index].job;
						this.mydata[index][0]['fleet'] = data['active_drivers'][index].fleet;
						console.log('array 0 0', this.mydata[index][0]);
						if (this.mydata[index] !== this.mydata_temp[index] && (this.mydata_temp[index] !== '' || this.mydata_temp[index] !== null || this.mydata_temp[index] !== undefined)) {
							this.realtimeMap()
						} else {
							this.mydata_temp[index] = this.mydata[index];
						}
					}
				});
			}
        }).finally(() => setTimeout(() => { this.loadingIndicator = false; }));
	}
	realtimeMap() {
		this.positions = [];
		let latValue: number, lngValue: number, title: string, description: string;
		console.log('ARRAY MYDATA', this.mydata);
		this.mydata = this.mydata.filter(function (el) {
			return el != null;
		  })
			title = "";
			description = "";
			for (let index = 0; index < this.mydata.length; index++) {
				if (this.mydata[index] != '') {
					latValue = this.mydata[index][0].lat;
					lngValue = this.mydata[index][0].lng;
					this.positions.push(this.mydata[index][0]);
					console.log('positions', this.positions);
				}
			}
			console.log('here are the positions');
			console.log(this.positions);
			this.fullPositions = this.positions;
			this.tablePositions = this.positions;
			for (let index = 0; index < this.tablePositions.length; index++) {
				if (this.tablePositions[index]['activity']['type'] !== undefined) {
					this.tablePositions[index]['activity'] = this.tablePositions[index]['activity']['type'];
				}
				this.tablePositions[index]['timestamp'] = this.tablePositions[index]['timestamp'];
				this.tablePositions[index]['driver'] = this.tablePositions[index]['firstname'] + ' ' + this.tablePositions[index]['lastname'];
				this.tablePositions[index]['title'] = this.tablePositions[index]['firstname'] + ' ' + this.tablePositions[index]['lastname'];
			}
			console.log(this.tablePositions);
	}

	clicked({target: marker},driver,description, fleet) {
		this.marker.lat = marker.getPosition().lat();
		this.marker.lng = marker.getPosition().lng();
		this.marker.driver = driver;
		this.marker.fleet = fleet;
		this.marker.description = description;
		marker.nguiMapComponent.openInfoWindow('iw', marker);
	  }
	  
	  hideMarkerInfo() {
		this.marker.display = !this.marker.display;
	  }

	getMapData() {
		this.mapsService.getDashboardData().subscribe(data => {
			if (data["total_cost"])
				this.totalCost = data['total_cost'];
			this.fleetCost = data['fleet_cost'];
			this.wageCost = data['wage_cost'];
			this.activities = data["job_activities"];
			this.expiries = data["expiries"];
			this.expiring_count = this.expiries.length;
			this.expired = data["expired"];
			this.expired_count = this.expired.length;
			this.fleet_assign_count = data['fleet_assign_count'];
		});
	}

	updateMap() {
		if (this.mapSearchTerm) {
			console.log('performing filter');
			this.temp = (this.fullPositions || []).filter(data => { 
				if (data.full_text)
					return data.full_text.toLowerCase().indexOf(this.mapSearchTerm.toLowerCase() ) !== -1 || !this.mapSearchTerm.toLowerCase();     
				else return data.full_text; 
			  });
			  this.tablePositions = this.temp;
			
				
		}
		else {
			console.log('blank filter');
			this.tablePositions = this.fullPositions;
			if(this.fullPositions != null){
				this.positions = this.fullPositions
			}
			this.mapZoom = 4;
			this.centerLat =  -33.395818;
			this.centerLng = 147.99444100000005;
		}

		
	}

	onSelect({ selected }) {
		console.log('Select Event', selected);
		this.positions = selected;
		if (this.positions.length  == 1) {
			this.centerLat = this.positions[0].lat;
			this.centerLng = this.positions[0].lng;
			this.mapZoom = 8;
		}
		else {
			this.mapZoom = 4;
			this.centerLat =  -33.395818;
			this.centerLng = 147.99444100000005;
		}
	  }

	public formatDate(inDate: string) {
		let formatedDate: Date;
		if (inDate && !inDate.includes('0000') ) {
			formatedDate = new Date(inDate.replace(/-/g, "/"));
			return formatedDate;
		}
		else return null;
	}
}