
import { Component, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { Options, ImageResult } from 'ngx-image2dataurl';


@Component({
  selector: 'app-file-uploader',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {
    @Output() passFile: EventEmitter<any> = new EventEmitter<any>();

    public error: any;
    public src: string = null;
    public fileName: string = null;
    public input: File;

    options: Options = {
      allowedExtensions: ['PDF', 'DOC', 'DOCX', 'MSWORD', 'JPEG', 'JPG', 'XLSX', 'XLS', 'PNG', 'RTF']
    };

    constructor(){
    }

    selected(fileResult: ImageResult) {
      if (fileResult.error) 
      {
        alert(fileResult.error);
      }else{
        this.src = fileResult.dataURL;
        this.fileName = fileResult.file.name;
        this.passFile.emit(fileResult);
      };
    }

    removeFile():void{
        this.src = '';
        this.fileName = '';
        this.passFile.emit('');
    }



}
