import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import { Search } from '../models/search.model';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../models/users.model';
import { LocalStorage } from 'ngx-store';


@Injectable()
export class SupportService {
    @LocalStorage() url: Url;

      private headers = new Headers({ 'Content-Type': 'application/json' });
      
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }
    constructor(public http:HttpClient) {
    }

	getBusinessProfile(): Observable<any> { 
		return this.http.get<any>(this.url.rest + '/tms/getBusinessProfile', this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            ) 
    }

    sendEmail(email_message): Observable<any> { 
		return this.http.post<any>(this.url.rest + '/tms/sendSupportMail', email_message,  this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            ) 
    }
   

    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }

}