import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [LoginService]

})

export class AppComponent implements OnInit {
  public settings: Settings;

  constructor(
    public appSettings: AppSettings,
    public userLoginService: LoginService
  ) {}

  ngOnInit() {
    this.settings = this.appSettings.settings;
  }
}
