import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import { User, Password, Account } from '../models/users.model';
import { AppSettings } from '../app.settings';
import { retry, catchError } from 'rxjs/operators';
import { Url} from '../models/users.model';
import { LocalStorage } from 'ngx-store';


@Injectable()


export class UsersService {
    @LocalStorage() url: Url;

    constructor(private http:HttpClient, public appSettings:AppSettings) { 
    }


    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }



	getUsers(){ 
		return this.http.get(this.url.rest  + '/tms/getMembers', this.options)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }
    
    createUser(member: User): Observable<User> {	
        return this.http.post<User>(this.url.rest  + '/tms/createMember', member , this.options)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    updateUser(member:Account): Observable<Account> {
        return this.http.put<Account>(this.url.rest  + '/tms/updateMember', member , this.options)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    updateAccount(account:Account): Observable<Account> {
        return this.http.put<Account>(this.url.rest  + '/customers/updateAccount', account , this.options)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    deleteUser(id: number) {
        return this.http.delete(this.url.rest  + '/tms/deleteMember/' + id, this.options)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }   

    updatePassword(password: Password): Observable<Password> {
        return this.http.put<Password>(this.url.rest  + '/customers/updatePassword', password , this.options)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }	


    updateCustomerAcctPassword(password: Password): Observable<Password> {
        return this.http.put<Password>(this.url.rest  + '/tms/updateCustomerAcctPassword', password , this.options)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }	



    updatePasswordOfOthers(password: Password): Observable<Password> {
        return this.http.put<Password>(this.url.rest  + '/tms/updatePasswordOfOthers', password , this.options)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }	

    updateUserStatus(id: number, value: number): Observable<any> {
        return this.http.put<any>(this.url.rest  + '/tms/updateMemberStatus/' + id + '/' + value, this.options)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    getUsersbyId(id: number) { 
        return this.http.get(this.url.rest  + '/tms/getMemberById/' + id, this.options)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    saveTheme(themeData:any) : Observable<any>{	
        return this.http.post<any>(this.url.rest  + '/tms/saveTheme', themeData, this.options)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    getActiveUsers(){ 
		return this.http.get(this.url.rest  + '/tms/getActiveMembers', this.options)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }


}