import { Component, ViewEncapsulation, ChangeDetectorRef,  Output, EventEmitter  } from '@angular/core';
import { Options, ImageResult } from 'ngx-image2dataurl';
import { Directive, ElementRef, Renderer2 } from '@angular/core';


@Component({
  selector: 'app-multiple-image-uploader',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './multiple-image-uploader.component.html',
  styleUrls: ['./multiple-image-uploader.component.scss'],
  exportAs: 'app-multiple-image-uploader',
})
export class MultipleImageUploaderComponent { 
    
    @Output() passImages : EventEmitter<any>= new EventEmitter<any>();

    public images: any[] = [];
    public renderImages: string[] = [];

    options: Options = {
      resize: {
        maxHeight: 128,
        maxWidth: 500
      },
      allowedExtensions: ['JPG', 'PnG']
    };

    constructor( private changeDetectorRef: ChangeDetectorRef ) {
    }    
    
    fileChange(input){
        this.readFiles(input.files);
    }
    
    readFile(file, reader, callback){
        reader.onload = () => {
            callback(reader.result);
        }
        reader.readAsDataURL(file);
    }
    
    readFiles(files, index=0){
        let reader = new FileReader();
        //this.images.pop();
        if (index in files){
            console.log(index);
            this.readFile(files[index], reader, (result) =>{
                this.images.push({name: files[index]["name"], dataUrl:result});
                this.renderImages.push(result);
                this.readFiles(files, index+1);
                
            });
        }else{
            this.changeDetectorRef.detectChanges();
        }
    }

    removeImage(index):void{
        this.renderImages.splice(index, 1);
        this.images.splice(index, 1);
    }

    selectedImages(imgResult: ImageResult) {
        console.log('imageresult')
      if (imgResult.error) alert(imgResult.error);
      
      this.passImages.emit(this.images);
    }

    

}
