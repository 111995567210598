/***
Feb 07, 2018 KA: Added for image uploader
***/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { ImageUploaderComponent } from './image-uploader.component';
import { ImageToDataUrlModule } from "ngx-image2dataurl";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImageToDataUrlModule,
  ],
  declarations: [ ImageUploaderComponent ],
  exports: [ ImageUploaderComponent ]
})

export class ImageUploaderModule { }