import { Component, OnInit, ViewEncapsulation, HostListener, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { LocalStorage, LocalStorageService } from 'ngx-store';
import { DataService } from '../services/data.service';
import { ThemeSetting } from '../models/users.model';
import { UsersService} from '../services/users.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  encapsulation: ViewEncapsulation.None,
    providers: [LocalStorageService, DataService, UsersService]

})
export class PagesComponent implements OnInit {
    @LocalStorage() themeSettings: ThemeSetting;

    public showMenu:boolean = false;
    public showHelp:boolean = false;        //newDeclaration
    public showSetting:boolean = false;    
    public menus = ['vertical', 'horizontal'];
    public menuOption:string;
    public menuTypes = ['default', 'compact', 'mini'];
    public menuTypeOption:string;
    switchValue: number;
    public settings: Settings;
    constructor(public appSettings:AppSettings, public router:Router, private data: DataService, private usersService: UsersService){        
        this.settings = this.appSettings.settings; 

        if(sessionStorage["skin"]) {
            // this.settings.theme.skin = sessionStorage["skin"];
            this.settings.theme.skin = this.themeSettings.theme;
        } ;
        this.settings.theme.menu = this.themeSettings.menu;
        this.settings.theme.menuType = this.themeSettings.menu_type;
        if (this.themeSettings.is_navbar_fixed == 1)  this.settings.theme.navbarIsFixed = true; else this.settings.theme.navbarIsFixed = false;
        if (this.themeSettings.is_sidebar_fixed == 1)  this.settings.theme.sidebarIsFixed = true; else this.settings.theme.sidebarIsFixed = false;
        if (this.themeSettings.is_footer_fixed == 1)  this.settings.theme.footerIsFixed = true; else this.settings.theme.footerIsFixed = false;
    }

    help:any;
    defaultHelp: any[] = [];

    helpContents = [];
    loadHelp: boolean = false;

    ngOnInit() {        
        if(window.innerWidth <= 768){
            this.settings.theme.showMenu = false;
            this.settings.theme.sideChatIsHoverable = false;
        }
        this.showMenu = this.settings.theme.showMenu;
        this.settings.theme.menuType = this.themeSettings.menu_type;

        //this.menuOption = this.settings.theme.menu;
        //this.menuTypeOption = this.settings.theme.menuType;    

        this.menuOption = this.themeSettings.menu;
        this.menuTypeOption = this.themeSettings.menu_type; 
        
        this.data.currentHelp.subscribe( help => this.help = help );
        
       
    }



    public chooseMenu(menu){
        this.settings.theme.menu = menu;

        // save theme to db
        const themeData = {
            id: this.themeSettings.id,
            key: 'menu',
            value: menu
        }
         this.usersService.saveTheme(themeData).subscribe(data => {
        });

        // update LocalStorage for theme settings
        let item = this.themeSettings;
        item['menu']= menu;
        localStorage.setItem('ngx_themeSettings', JSON.stringify(item));

        this.router.navigate(['/']);      

        
        
    }

    public chooseMenuType(menuType){
        this.settings.theme.menuType = menuType;
        if(menuType=='mini'){
            jQuery('.menu-item-link').tooltip('enable');
        }else{
            jQuery('.menu-item-link').tooltip('disable');
        }


         // save theme to db
         const themeData = {
            id: this.themeSettings.id,
            key: 'menu_type',
            value: menuType
        }
         this.usersService.saveTheme(themeData).subscribe(data => {
        });

        // update LocalStorage for theme settings
        let item = this.themeSettings;
        item['menu_type']= menuType;
        localStorage.setItem('ngx_themeSettings', JSON.stringify(item));
    }

    public changeTheme(theme: string){
        console.log('changing theme');
        this.settings.theme.skin = theme;
        sessionStorage["skin"] = theme;      
        const themeData = {
            id: this.themeSettings.id,
            key: 'theme',
            value: theme
        }

        // save theme to db
        this.usersService.saveTheme(themeData).subscribe(data => {
            console.log('THEME SAVE DATA', data);
        });

        // update LocalStorage for theme settings
        let item = this.themeSettings;
        item['theme']= theme;
        localStorage.setItem('ngx_themeSettings', JSON.stringify(item));
        

        console.log('updated');

        
        
    }


    changeNavbar() {
        this.settings.theme.navbarIsFixed = !this.settings.theme.navbarIsFixed;
        if (this.settings.theme.navbarIsFixed) this.switchValue = 1; else this.switchValue = 0;
        const themeData = {
            id: this.themeSettings.id,
            key: 'is_navbar_fixed',
            value: this.switchValue
        } 
        // save theme to db
        this.usersService.saveTheme(themeData).subscribe(data => {
            console.log('THEME SAVE DATA', data);
        });

        // update LocalStorage for theme settings
        let item = this.themeSettings;
        item['is_navbar_fixed']= this.switchValue;
        localStorage.setItem('ngx_themeSettings', JSON.stringify(item));

    }

    changeSidebar() {
        this.settings.theme.sidebarIsFixed = !this.settings.theme.sidebarIsFixed;
        if (this.settings.theme.sidebarIsFixed) this.switchValue = 1; else this.switchValue = 0;
        const themeData = {
            id: this.themeSettings.id,
            key: 'is_sidebar_fixed',
            value: this.switchValue
        } 
        // save theme to db
        this.usersService.saveTheme(themeData).subscribe(data => {
            console.log('THEME SAVE DATA', data);
        });

        // update LocalStorage for theme settings
        let item = this.themeSettings;
        item['is_sidebar_fixed']= this.switchValue;
        localStorage.setItem('ngx_themeSettings', JSON.stringify(item));

    }

    changeFooter() {
        this.settings.theme.footerIsFixed = !this.settings.theme.footerIsFixed;
        if (this.settings.theme.footerIsFixed) this.switchValue = 1; else this.switchValue = 0;
        const themeData = {
            id: this.themeSettings.id,
            key: 'is_footer_fixed',
            value: this.switchValue
        } 
        // save theme to db
        this.usersService.saveTheme(themeData).subscribe(data => {
            console.log('THEME SAVE DATA', data);
        });

        // update LocalStorage for theme settings
        let item = this.themeSettings;
        item['is_footer_fixed']= this.switchValue;
        localStorage.setItem('ngx_themeSettings', JSON.stringify(item));

    }
 
    ngAfterViewInit(){
        document.getElementById('preloader').classList.add('hide');
        /*
        setTimeout(() => {
            this.helpContents = JSON.parse(this.help);
        });
        */
    }



    @HostListener('window:resize')
    public onWindowResize():void {
        let showMenu= !this._showMenu();

        if (this.showMenu !== showMenu) {
            this.showMenuStateChange(showMenu);
        }
        this.showMenu = showMenu;
    }

    public showMenuStateChange(showMenu:boolean):void {
        this.settings.theme.showMenu = showMenu;
    }

    private _showMenu():boolean {
        return window.innerWidth <= 768;
    }

}
