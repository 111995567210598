import { NumberValueAccessor } from '@angular/forms';

export class PeopleContact {
    id: number;
    first_name: string;
    last_name: string;
    address: string;
    abn: string;
    phone: string;
    fax: string;
    mobile: string;
    email: string;
    photo: string;
    position: string;
    created_id: number;
    updated_id: number;
 }

export class ContactAffiliate{
    id: number;
    contact_id: number;
    company_id : number;
    dedicated_phone: string;
    dedicated_email: string;
    dedicated_mobile: string;
    created_id: number;
    updated_id: number;
    type: string;
    contact_types: ContactTypes[];
}


export class ContactTypes{
    id: number;
    company_contact_id: number;
    contact_type_id : number;
    email_notification: number;
    sms_notification: number;
    is_enabled:number;
    ///
    type_name:string;
}
 