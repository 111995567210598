import { Injectable } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import { FileExport } from './../exporter/exporter.model';
import { AppSettings } from '../../app.settings';
import { retry, catchError } from 'rxjs/operators';
import { Url} from './../../models/users.model';

import { LocalStorage } from 'ngx-store';




@Injectable({
  providedIn: 'root'
})

export class ExportService {
    @LocalStorage() url: Url;

    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    options_csv = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
    };

    csvExporter : ExportToCsv;

    constructor(private exporter: ExportService, private http:HttpClient, public appSettings:AppSettings) { 
        this.csvExporter = new ExportToCsv(this.options_csv);
    }


    exportToCSV(list_json : any, filename_csv:string, title: string){
        this.csvExporter.options.filename = filename_csv;
        this.csvExporter.options.title = title;
        this.csvExporter.generateCsv(list_json);
    }

    exportToPDF(data_to_export: FileExport):Observable<any>{ 
        return this.http.post(this.url.rest  + '/exporter/exportPDFFile',  data_to_export, { responseType: 'blob' })
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            )
    }

    exportToExcel(data_to_export: FileExport):Observable<any>{ 
        return this.http.post(this.url.rest  + '/exporter/exportFile',  data_to_export, { responseType: 'blob' })
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            )
    }

    // Error handling
    errorHandl(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(error);
        return throwError(errorMessage);
    }

}