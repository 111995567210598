import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfilePicturePipe } from './profilePicture/profilePicture.pipe';
import { ChatPersonSearchPipe } from './search/chat-person-search.pipe';
import { UserSearchPipe } from './search/user-search.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { MailSearchPipe } from './search/mail-search.pipe';
import { SearchKeySearchPipe } from './search/searchkey-search.pipe';
import { SafePipe } from './safe.pipe';
import { FilterPipe } from './search/filter.pipe';
import { DocumentsSearchPipe } from './search/document-search.pipe';
import { MemberSearchPipe } from './search/member-search.pipe';
import { JobNotificationSearchPipe} from './search/jobnotification-search.pipe';
import { CustomersSearchPipe } from './search/customer-search.pipe';
import { BookingSearchPipe} from './search/booking-search.pipe';
import { JobSearchPipe} from './search/job-search.pipe';
import { ContactSearchPipe} from './search/contact-search.pipe';
import { DefaultRateSearchPipe} from './search/defaultrate-search.pipe';
import { FleetSearchPipe } from './search/fleet-search.pipe';
import { ActivitiesSearchPipe} from './search/activities-search.pipe';

@NgModule({
    imports: [ 
        CommonModule 
    ],
    declarations: [
        ProfilePicturePipe,
        ChatPersonSearchPipe,
        UserSearchPipe,
        TruncatePipe,
        MailSearchPipe,
        SearchKeySearchPipe,
        SafePipe,
        FilterPipe,
        DocumentsSearchPipe,
        MemberSearchPipe,
        JobNotificationSearchPipe,
        CustomersSearchPipe,
        JobSearchPipe,
        BookingSearchPipe,
        ContactSearchPipe,
        DefaultRateSearchPipe,
        FleetSearchPipe,
        ActivitiesSearchPipe
    ],
    exports: [
        ProfilePicturePipe,
        ChatPersonSearchPipe,
        UserSearchPipe,
        TruncatePipe,
        MailSearchPipe,
        SearchKeySearchPipe,
        SafePipe,
        FilterPipe,
        DocumentsSearchPipe,
        MemberSearchPipe,
        JobNotificationSearchPipe,
        CustomersSearchPipe,
        JobSearchPipe,
        BookingSearchPipe,
        ContactSearchPipe,
        DefaultRateSearchPipe,
        FleetSearchPipe,
        ActivitiesSearchPipe
    ]
})
export class PipesModule { }
