import { AgmCoreModule } from '@agm/core';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NguiMapModule } from '@ngui/map';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { MyDatePickerModule } from 'mydatepicker';
import { NgxGeoautocompleteModule } from "ngx-geoautocomplete-v2";
import { ImageToDataUrlModule } from 'ngx-image2dataurl';
import { NgxLoadingModule } from 'ngx-loading';
import { OrderModule } from 'ngx-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { LocalStorageService } from 'ngx-store';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CoreModule } from 'src/lib/core/core.module';
import { IntegrationsModule } from 'src/lib/integrations/integrations.module';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { AppSettings } from './app.settings';
import { FileUploaderModule } from './pages/controls/file-uploader/file-uploader.module';
import { ImageUploaderModule } from './pages/controls/image-uploader/image-uploader.module';
import { MultipleImageUploaderModule } from './pages/controls/multiple-image-uploader/multiple-image-uploader.module';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { PagesComponent } from './pages/pages.component';
import { SearchComponent } from './pages/search/search.component';
import { SupportComponent } from './pages/support/support.component';
import { SharedModule } from './shared/shared.module';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { BackTopComponent } from './theme/components/back-top/back-top.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { FavoritesComponent } from './theme/components/favorites/favorites.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { HeaderComponent } from './theme/components/header/header.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { SideChatComponent } from './theme/components/side-chat/side-chat.component';
import { SidebarComponent } from './theme/components/sidebar/sidebar.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { RatesComponent } from './pages/rates/rates.component';
import { MapTrackerComponent } from './pages/maptracker/maptracker.component';
import { PeopleContactsComponent } from './pages/peoplecontacts/peoplecontacts.component';
import { PipesModule } from './theme/pipes/pipes.module';

import { JobNotificationsComponent } from './pages/jobnotifications/jobnotifications.component';
import { JobsService } from './services/jobs.service';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};






@NgModule({  
  imports: [
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    NgbModule,
    MultiselectDropdownModule,
    NgxDatatableModule,
    NgxLoadingModule.forRoot({
      backdropBackgroundColour: 'rgba(240,240,240,0.5)',
      backdropBorderRadius: '10px',
      primaryColour: '#aaa', 
      secondaryColour: '#aaa', 
      tertiaryColour: '#aaa'
    }),
    ImageToDataUrlModule,
    NguiMapModule.forRoot({
      apiUrl: 'https://maps.google.com/maps/api/js?key=AIzaSyBkYKcGdwnLd4FF__mCJxU23gMArZCvYTQ' +
        '&libraries=visualization,places,drawing',
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAir4tXhx3X-wcdZnhe8TLlo9J2m_AKx6w'
    }),
    ToastrModule.forRoot(), 
    PipesModule,
    ImageUploaderModule,
    FileUploaderModule,
    MultipleImageUploaderModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    NgxPaginationModule,
    NgxChartsModule,
    AngularEditorModule,
    OrderModule,
    SharedModule,
    AngularMyDatePickerModule,
    MyDatePickerModule,
    NgxGeoautocompleteModule.forRoot(),
    routing,
    IntegrationsModule
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    BackTopComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    FlagsMenuComponent,
    SideChatComponent,
    FavoritesComponent,
    SearchComponent,
    SupportComponent,
    NotFoundComponent,
    ProfileComponent,
    RatesComponent,
    MapTrackerComponent,
    JobNotificationsComponent,
    PeopleContactsComponent
    ],
  providers: [
    AppSettings,
    JobsService,
    LocalStorageService,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
