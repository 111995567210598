import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Endpoint } from 'src/lib/models/endpoint';
import { AuthStorage, PasswordGrantCreds, Token } from '../../models/oauth';
import { AuthResponse, extractTokenFrom, grantStorage, tokenStorage } from './auth.config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // password grant credentials, excluding `username` and `password`
  private cachedGrant: PasswordGrantCreds;

  // token credentials, cached from the `storage` that has been set
  private cachedToken: Token | null;

  // optional URI to navigate back to after authenticating
  public redirectUri: string | null;

  constructor(
    private http: HttpClient
  )
  {
    this.redirectUri = null;
    this.readTokenFrom(tokenStorage).subscribe();

    let grant = this.readPasswordGrantCredsFrom(grantStorage);
    if(!!grant) {
      this.cachedGrant = grant;
      console.debug('Password grant credentials is cached.');
    }
  }

  /**
   * Get token credentials.
   */
  public get token(): Token | null {
    return this.cachedToken;
  }

  /**
   * Get cached password grant credentials.
   */
  public get grantCreds(): PasswordGrantCreds {
    return this.cachedGrant;
  }

  /**
   * Store token credentials to cache.
   *
   * @param {Token | null} token Token to cache.
   */
  private cache(token: Token | null): Token | null {
    this.cachedToken = token;

    console.debug(
      this.token
        ? 'Token credentials are cached.'
        : 'Token credentials are cleared from cache.'
    );

    return this.token;
  }

  /**
   * Check whether the access token has expired, if it exists.
   * Return `true` or `false` whether token is expired, and
   * `null` if client does not have the token credentials.
   */
  get isExpired(): boolean | null {
    if(!this.token) {
      return null;
    }

    let expiryDate = this.token.issued_at + this.token.expires_in * 1000;
    let currentDate: number = Date.now();

    return currentDate > expiryDate;
  }

  /**
   * Check the validity of the tokens. Return true if client has been
   * issued tokens that are not yet expired. False otherwise.
   */
  get isAuthenticated(): boolean {
    return this.token && !this.isExpired;
  }

  /**
   * Configure the auth service on how to authenticate via the Oauth 2.0
   * password grant flow.
   *
   * @param {PasswordGrantCreds} creds Password grant credentials.
   */
  init(creds: PasswordGrantCreds): AuthService {
    this.persistClientCredsTo(grantStorage, creds);
    this.cachedGrant = creds;
    console.debug('Password grant credentials is cached.');
    return this;
  }

  /**
   * Request for an access token (along with a refresh token and others)
   * from the authorization server if none retrieved yet.
   *
   * @param {string} username User's username.
   * @param {string} password User's password.
   */
  authenticate(username: string, password: string): Observable<Partial<AuthResponse>> {
    // if client already has issued tokens
    if(this.token) {
      let response: Partial<AuthResponse> = {
        token: this.token
      }
      return of(response);
    }

    let reqBody = {
      'grant_type': this.cachedGrant.grantType,
      'client_id': this.cachedGrant.clientId,
      'client_secret': this.cachedGrant.clientSecret,
      'scope': this.cachedGrant.scope,
      'username': username,
      'password': password
    };

    let authEndpoint: Endpoint = this.cachedGrant.authEndpoint;

    console.debug('Client requests for an access token.');

    return this.http[authEndpoint.method](authEndpoint.route as string, reqBody)
    .pipe(
      tap((response: AuthResponse): Observable<AuthResponse> => {
        let token = extractTokenFrom(response);

        console.debug('Client receives new tokens.');
        return this
          .persistTokenTo(tokenStorage, token)
          .pipe(() => of(response));
      }),
      catchError(logAuthError)
    );
  }

  /**
   * Refresh access token then return the new tokens. Failing to refresh
   * the access token means that the token credentials the client has is
   * invalid and therefore will be cleared from the client.
   *
   * @returns {Observable<Token | null}
   */
  refreshToken(): Observable<Token | null> {
    console.debug('Client attempts to refresh its token credentials.');

    let refresh: Endpoint = this.cachedGrant.refreshEndpoint;
    let reqBody = {
      'grant_type': 'refresh_token',
      'refresh_token': this.token.refresh_token,
      'client_id': this.cachedGrant.clientId,
      'client_secret': this.cachedGrant.clientSecret,
      'scope': this.cachedGrant.scope
    };

    return this.http[refresh.method]<Token>(refresh.route as string, reqBody)
    .pipe(
      tap<Token | null>((token: Token): Observable<Token> => {
        console.debug('Token credentials are refreshed.');
        return this.persistTokenTo(tokenStorage, token);
      }),
      catchError(logAuthError),
      catchError((response: HttpErrorResponse): Observable<Token> => {
        console.debug('Token credentials cannot be refreshed. so it will be purged from storage.');
        return this.clearTokenFrom(tokenStorage)
        .pipe(
          switchMap(() => throwError(response))
        );
      })
    );
  }

  /**
   * Revoke token credentials of the client from the server, essentially
   * unauthenticating the user. Return `null` on successful revocation,
   * otherwise return the token the client currently has.
   *
   * @returns {Observable<Token | null}
   */
  revokeToken(): Observable<Token | null> {
    console.debug('Client attempts to revoke token credentials from the server.');

    if(!this.token) {
      console.debug('Client does not have the token credentials.');
      return of(null);
    }

    // if token is expired
    if(this.isExpired) {
      console.debug('Access token is expired.');

      // refresh token before revoking
      return this.refreshToken()
      .pipe(
        switchMap((token: Token): Observable<Token> => {
          return this.revokeToken();
        })
      );
    }

    let revoke: Endpoint = this.cachedGrant.revokeEndpoint;
    let revokeRoute = (revoke.route as string).replace('{{token}}', this.token.access_token);

    return this.http[revoke.method](revokeRoute)
    .pipe(
      switchMap((response: HttpResponse<Object>): Observable<Token | null> => {
        console.debug('Token credentials are revoked from the server.');
        this.clearPasswordGrantFrom(grantStorage);
        return this.clearTokenFrom(tokenStorage);
      }),
      catchError((response: HttpErrorResponse) => {
        if(response.status === 401) {
          return this.refreshToken()
          .pipe(
            switchMap((token: Token): Observable<Token> => {
              return this.revokeToken();
            })
          );
        }
        else return throwError(response);
      }),
      catchError((response: HttpErrorResponse) => {
        console.warn('Token credentials are not revoked from the server.', response.error);
        this.clearPasswordGrantFrom(grantStorage);
        return this.clearTokenFrom(tokenStorage);
      })
    );
  }

  /**
   * Store tokens to a persistence storage, cache it and return it.
   *
   * @param {AuthStorage} storage Where to store the tokens to.
   * @param {Token} token Token to store.
   */
  private persistTokenTo(storage: AuthStorage, token: Token): Observable<Token | null> {
    if(token && !anyIsNull(Object.values(token))) {
      Object.keys(token).forEach((key: string) => {
        storage.set(`${storage.prefix}_${key}`, String(token[key]));
      });

      // store the date the token is issued
      storage.set(`${storage.prefix}_issued_at`, Date.now().toString());

      console.debug('Token credentials are persisted to storage.');

      return this.readTokenFrom(storage)
      .pipe<Token>(
        tap<Token>((token: Token): Observable<Token> => {
          this.cache(token);
          return of(this.token);
        })
      );
    }
  }

  /**
   * Store password grant credentials.
   * 
   * @param {AuthStorage} storage Where to store the password grant creds to.
   * @param {PasswordGrantCreds} creds Password grant credentials to store.
   */
  private persistClientCredsTo(storage: AuthStorage, creds: PasswordGrantCreds): void {
    if(!!creds) {
      let encoding = JSON.stringify(creds);
      storage.set(storage.prefix, encoding);

      console.debug('Password grant credentials are persisted to storage.');
    }
  }

  /**
   * Retrieve token credentials from the specified storage cache it.
   *
   * @param {AuthStorage} storage Storage used to look for the credentials.
   * @returns {Token | null}
   */
  private readTokenFrom(storage: AuthStorage): Observable<Token | null> {
    let token: Token = null;

    // get token info from storage
    let token_type: string = storage.get(`${storage.prefix}_token_type`);
    let access_token: string = storage.get(`${storage.prefix}_access_token`);
    let refresh_token: string = storage.get(`${storage.prefix}_refresh_token`);
    let expiry: string = storage.get(`${storage.prefix}_expires_in`);
    let issuance: string = storage.get(`${storage.prefix}_issued_at`);

    // if info is incomplete
    if(anyIsNull(token_type, access_token, refresh_token, expiry, issuance)) {
      console.debug('No token credentials are found in the token storage.');
    }
    else {
      // parse info to its proper type
      token = {
        token_type: token_type,
        access_token: access_token,
        refresh_token: refresh_token,
        expires_in: Number(expiry),
        issued_at: Number(issuance)
      };

      console.debug('Token credentials are read from the token storage.');
    }

    this.cache(token);
    return of(this.token);
  }

  /**
   * Read password grant credentials from storage.
   * 
   * @param storage Storage to read password grant credentials from.
   */
  private readPasswordGrantCredsFrom(storage: AuthStorage): PasswordGrantCreds {
    let decoding = null;
    let encoding = storage.get(storage.prefix);

    if(!!encoding) {
      decoding = JSON.parse(encoding);
      console.debug('Password grant credentials is read from storage.');
    }

    return decoding;
  }

  /**
   * Remove token credentials from the client storage. Return `null` if token
   * credentials are successfully cleared from storage, otherwise return the
   * token credentials that still persists in the storage.
   *
   * @param storage Storage to remove tokens from.
   * @returns {Observable<Token | null>}
   */
  private clearTokenFrom(storage: AuthStorage): Observable<Token | null> {
    console.debug('Client attempts to clear token credentials from storage.');

    Object.keys(this.token).forEach((key: string) => {
      storage.unset(`${storage.prefix}_${key}`);
    });

    return this.readTokenFrom(storage)
    .pipe<Token | null>(
      tap((token: Token | null): Observable<Token | null> => {
        console.debug(
          this.token
            ? 'Token credentials are not cleared from the token storage.'
            : 'Token credentials are cleared from the token storage.'
        );

        return of(this.token);
      })
    );
  }

  /**
   * Remove password grant credentials from storage.
   * 
   * @param {AuthStorage} storage Storage to remove password grant credentials from.
   */
  private clearPasswordGrantFrom(storage: AuthStorage): void {
    storage.unset(storage.prefix);
  }
}

/**
 * Helper function that returns true if at least one of the passed
 * arguments is `null`, otherwise false.
 *
 * @param varargs Variable arguments.
 * @returns {boolean} `True` if any of the passed arguments is `null`, `false` otherwise.
 */
function anyIsNull(...varargs: any): boolean {
  return varargs.find((arg: any) => arg === null) === null;
}

/**
 * Handle authentication error.
 *
 * @param {HttpErrorResponse} response Error response.
 * @returns {Observable<never>}
 */
function logAuthError(response: HttpErrorResponse): Observable<never> {
  console.debug('Client is denied new tokens.');
  return throwError(response);
}