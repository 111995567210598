import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, mapTo, switchMap } from 'rxjs/operators';
import { loggedInUri, loginUri, publicClientRoutes } from './auth.config';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(
    private router: Router,
    private auth: AuthService,
  ) { }

  /**
   * Load modules only if the user trying to access it is authenticated.
   *
   * @param {Route} route
   * @param {UrlSegment[]} segments
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean
  {
    let destRoute = `/${segments.filter(x => x.path).join('/')}`;
    let wantsToSignIn =  destRoute === Location.stripTrailingSlash(loginUri);

    console.debug(`App attempts to load guarded module at path \`${destRoute}\`.`);

    // if unauthenticated user wants to login
    if(!this.auth.token && wantsToSignIn) {
      console.debug('App loads module so the user can login.');
      return true;
    }

    // in case token credentials do not exist
    if(!this.auth.token) {
      console.debug('Client does not have any token credentials.');
      console.debug('App denies to load the module, and redirects user to the sign-in route.');
      this.router
      .navigate([ loginUri ])
      .then((isRedirected: boolean) => {
        if(isRedirected) {
          console.debug(`App successfully navigates to the sign-in route at \`${loginUri}\`.`);
        }
        else {
          console.error(`App fails to navigate to the sign-in route at \`${loginUri}\`.`);
        }
      });
      return false;
    }

    // in case access token is expired
    if(this.auth.isExpired) {
      console.debug('Access token is expired.');

      // refresh the token
      return this.auth.refreshToken()
      .pipe(
        // load module unless the user unnecessarily insists on re-logging in
        mapTo(!wantsToSignIn),
        catchError(() => {
          console.debug('App denies to load the module, and redirects user to the sign in route.');
          this.router
            .navigate([ loginUri ])
            .then((isRedirected: boolean) => {
              if(isRedirected) {
                console.debug(`App successfully navigates to the logged in route at \`${loginUri}\`.`);
              }
              else {
                console.error(`App fails to navigate to the logged in route at \`${loginUri}\`.`);
              }
            });
          return of(false);
        })
      );
    }

    // if already-authenticated user still wants to sign in
    if(wantsToSignIn) {
      console.debug('Client already is authenticated.');
      console.debug('App denies to load the module.');
      return false;
    }

    // at this point the user is qualified to load the module
    console.debug('Client is authenticated so the app loads the module.');
    return true;
  }

  /**
   * Only activate routes if any of the following conditions are met:
   * * the user is unauthenticated and wants to go to the sign-in page,
   * * the route is whitelisted in the auth config's public client routes,
   * * or the user trying to access a protected route is authenticated.
   *
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    // if unauthenticated user wants to login
    if(!this.auth.token && state.url === loginUri) {
      return true;
    }

    // if the route to be accessed is configured to be allowed without authentication
    if(publicClientRoutes.includes({ route: state.url })) {
      return true;
    }

    // at this point we know the user needs to be authenticated first
    console.debug(`Client attempts to navigate to the protected route at \`${state.url}\`.`);

    // if client credentials does not exist
    if(!this.auth.token) {
      // redirect user to the sign-in page
      console.debug('Client is redirected to the sign in route.');

      this.auth.redirectUri = state.url;
      return this.router.parseUrl(loginUri);
    }

    // if access token if expired
    if(this.auth.isExpired) {
      console.debug('Access token is expired.');

      return this.auth.refreshToken()
      .pipe(
        switchMap((): Observable<boolean> => {
          return of(true);
        }),
        // if new token can't be issued using the refresh token
        catchError((): Observable<UrlTree> => {
          console.debug(`Client fails to access the protected route at \`${state.url}\`.`);
          return of(this.router.parseUrl(loginUri));
        })
      )
    }

    // if client is authenticated but is trying to go to the sign in route
    if(state.url === loginUri) {
      console.debug('Client already has token credentials.');
      console.info('You are already authenticated. Only unauthenticated users are allowed to access this route.');
      return this.router.parseUrl(loggedInUri);
    }

    // phew, finally at this point we now know the user is qualified to
    // access the route
    console.debug('Client has access to the protected route.');
    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    return this.canActivate(route, state);
  }
}
