import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { FormBuilder, FormGroup, Form, Validators } from '@angular/forms';
import {  AngularEditorConfig  } from '@kolkov/angular-editor';
import * as $ from 'jquery';
import { MultipleImageUploaderComponent } from '../controls/multiple-image-uploader/multiple-image-uploader.component';
import { DataService } from '../../services/data.service';
import  *  as  helpData  from  '../../data/help/support.json';
import { Url, User } from './../../models/users.model';
import { LocalStorage } from 'ngx-store';
import { SupportService } from '../../services/support.service';
import { NotificationService } from '../../services/notification.service';




@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ SupportService ]
})


export class SupportComponent implements OnInit {
  @LocalStorage() url: Url;
  @LocalStorage() user: User;


  error: any;
  loadingIndicator: boolean = true;
  help: any;
  mailForm: FormGroup;
  to: string ='';
  logo: string ='';
  company_name: string = '';
  address: string = '';
  email_sent:boolean = false;
  spinner : boolean =false;
  regex_email: RegExp;

  constructor(private http: HttpClient, private _mailer: FormBuilder,  private data: DataService, private svc_support: SupportService,
              private svc_notif : NotificationService) { 

    this.regex_email = new RegExp(/^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;,.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/);
    this.mailForm = this._mailer.group({
      to: ['',[Validators.required, Validators.pattern(this.regex_email)]],
      from: ['',[Validators.required , Validators.pattern(this.regex_email)]],
      subject: ['',Validators.required],
      body: ['',Validators.required],
      //attachment: this._mailer.array([])
    })

  }

  ngOnInit() {  

    this.svc_support.getBusinessProfile().subscribe(data => {
      this.to = data['profile'][2]['string'];// business operations email
      this.address = data['profile'][4]['string'];
      this.logo = data['profile'][9]['string'];
      this.company_name = data['profile'][0]['string'];

      this.mailForm.controls.to.setValue(this.to);
      this.mailForm.controls.from.setValue(this.user.email);
		});

    $(document).ready(function() {

      $(".btn-info").click(function(){
          var html = $(".clone").html();
          $(".increment").after(html);
      });

      $("body").on("click",".btn-danger",function(){
          $(this).parents(".control-group").remove();
      });

    });

    this.data.currentHelp.subscribe(help => this.help = help);
    setTimeout(() => { this.loadingIndicator = false; });
  }


  ngAfterViewInit() {
		this.data.changeHelp(helpData["default"]);
	}


  initFiles() {
    const group = this._mailer.group({
      file: [''],
    });

    return group; 
  }

  getAttachmentControl() {
    const attCtrl = (<any>this.mailForm).controls.attachment;
    return attCtrl.controls;
  }

  addAttachment() {
    // add address to the list
    console.log('adding additional address');
    const control = (<any>this.mailForm).controls['attachment'];
  
    control.push(this.initFiles());
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '200px',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
      ],
      [
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  uploadFile(event) {
    const file = (event.target as HTMLInputElement)
    this.mailForm.patchValue({
      avatar: file
    });
    this.mailForm.get('avatar').updateValueAndValidity()
    console.log('mailform',this.mailForm.value.avatar)
  }

  onSubmitEmail() {
    this.spinner = true;
    // var formData: any = new FormData();
    // formData.append("subject", this.mailForm.get('subject').value);
    // formData.append("body", this.mailForm.get('body').value);
    // //formData.append("avatar", this.mailForm.get('avatar').value);
    // this.http.post(this.url.rest  + '/forms', formData).subscribe(
    //   (response) => console.log(response),
    //   (error) => console.log(error)
    // );

    const emailMessage = {
      to: this.to,
      from: this.user.email,
      subject:  this.mailForm.get('subject').value,
      content: this.mailForm.get('body').value,
      app: 'Customer Portal',
      logo: this.logo,
      company_name : this.company_name,
      address : this.address
    };

    this.svc_support.sendEmail(emailMessage).subscribe(data => {
      if(data['error']==false){
        this.svc_notif.showSuccess('Thank you for your reaching out to us! Our Support team will get back to you soon.', 'Email is successfully sent.');
      }else{
        this.svc_notif.showError('Kindly try again.', 'Email was not sent.');
      };

      this.reset();
      setTimeout(() => { this.loadingIndicator = false; this.email_sent = true; this.spinner = false;
      }, 3000);
    });

  }

  reset(){
    this.mailForm.reset();
    this.to = '';
    this.logo = '';
    this.company_name = '';
    this.address = '';
    this.ngOnInit();
  }

}



  