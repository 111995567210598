import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {

  private helpSource = new BehaviorSubject([]);
  currentHelp = this.helpSource.asObservable();

  constructor() { }

  changeHelp(help: any) {
      this.helpSource.next(help)    
  }

}