import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Url } from '../models/users.model';
import { LocalStorage } from 'ngx-store';
import { PeopleContact, ContactAffiliate, ContactTypes } from '../models/peoplecontacts.model'


@Injectable()
export class PeopleContactsService {
    @LocalStorage() url: Url;

  	private headers = new Headers({ 'Content-Type': 'application/json' });
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }    
    constructor(public http:HttpClient) {
     }

	getPeopleContactList(filter, id): Observable<any[]> { 
        return this.http.get<any[]>(this.url.rest + '/tms/getPeopleContactList/' + filter + '/' + id, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )   
    }

    getContactTypes(type, id): Observable<any[]> { 
        return this.http.get<any[]>(this.url.rest + '/tms/getContactTypes/' + type + '/' + id, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )   
    }
    

    createContact(contact:PeopleContact): Observable<PeopleContact> {	
        return this.http.post<PeopleContact>(this.url.rest  + '/tms/createPplContact', contact , this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    updateContact(contact:PeopleContact): Observable<PeopleContact> {
        return this.http.put<PeopleContact>(this.url.rest  + '/tms/updatePplContact', contact , this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    deleteContact(id: number) {
        return this.http.delete(this.url.rest  + '/tms/deletePplContact/' + id);
    }

    createContactAffiliate(ca:ContactAffiliate): Observable<ContactAffiliate> {	
        return this.http.post<ContactAffiliate>(this.url.rest  + '/tms/createContactAffiliate', ca ,  this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    updateContactAffiliate(ca:ContactAffiliate): Observable<ContactAffiliate> {
        return this.http.put<ContactAffiliate>(this.url.rest  + '/tms/updateContactAffiliate', ca , this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    deleteContactAffiliate(id: number, type: string) {
        return this.http.delete(this.url.rest  + '/tms/deleteContactAffiliate/' + id + '/' + type);
    }

    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }

}