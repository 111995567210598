/***Added for general search***/

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'SearchKeySearchPipe', pure: false })
export class SearchKeySearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(searchkey => {
        if (searchkey.tags) {
          return searchkey.tags.search(searchText) !== -1;
        }
      });
    }
  }
}