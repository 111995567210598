import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'CustomersSearchPipe', pure: false })
export class CustomersSearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(customer => {
        if (customer.company_name) {
          return customer.company_name.search(searchText) !== -1;
        }
      });
    }
  }
}