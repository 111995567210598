import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'JobSearchPipe', pure: false })
export class JobSearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(job => {
        if (job.control_no) {
          return job.control_no.search(searchText) !== -1;
        }
      });
    }
  }
}