import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AuthInterceptor } from './authentication/auth.interceptor';
import { AuthService } from './authentication/auth.service';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [AuthService]
    }
  ],
  exports: [
    BrowserModule,
    HttpClientModule
  ]
})

/**
 * This module aggregates all functionalities necessary in order for
 * the application to run, and provides utilities that are scoped globally.
 * This module is intended to be loaded only once and will throw an error
 * if loaded multiple times.
 */
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // if developer tries to load this more than once.
    if(parentModule) {
      throw new Error('CoreModule has already been loaded. Please load only to the root module.');
    }
  }
}
