import { Injectable } from '@angular/core';
import { DefaultRate,Customer,Contact } from '../models/rates.model';
import { User, Url} from '../models/users.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';

import { LocalStorage } from 'ngx-store';


@Injectable()
export class RatesService {
    
    @LocalStorage() token: string;
    @LocalStorage() user: User;
    @LocalStorage() url: Url;

  	private headers = new Headers({ 'Content-Type': 'application/json' });
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }


    constructor(public http:HttpClient) { 
    }



    getCustomer(id:number): Observable<Customer> { 
        return this.http.get<Customer>(this.url.rest  + '/tms/getCustomer/' + id, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

  
    updateCustomer(customer:Customer): Observable<Customer> {
        return this.http.put<Customer>(this.url.rest  + '/tms/updateCustomer', customer , this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

 
    getDefaultRatesByCustomerId(id:number): Observable<Contact> { 
        return this.http.get<Contact>(this.url.rest  + '/tms/getCustomerDefaultRatesByCustomer/' + id, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    getStates(): Observable<any[]> { 
        return this.http.get<any[]>(this.url.rest  + '/tms/getStates', this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    getJobTypes(): Observable<any[]> { 
        return this.http.get<any[]>(this.url.rest  + '/tms/getJobTypes', this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    getPaymentMethods(): Observable<any[]> { 
        return this.http.get<any[]>(this.url.rest  + '/tms/getPaymentMethods', this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    getChargingMethods(): Observable<any[]> { 
        return this.http.get<any[]>(this.url.rest  + '/tms/getChargingMethods', this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    getServiceTypes(): Observable<any[]> { 
        return this.http.get<any[]>(this.url.rest  + '/tms/getServiceTypes', this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }
    
    getInvoiceBanks(): Observable<any[]> { 
        return this.http.get<any[]>(this.url.rest  + '/tms/getInvoiceBanks', this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    updateCustomerInvoicePayment(customerInvoiceDefault:any): Observable<any> {
        return this.http.put(this.url.rest  + '/tms/updateCustomerInvoicePayment', customerInvoiceDefault , this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }


    updateDefaultRate(rate: DefaultRate): Observable<Contact> {
        return this.http.put<Contact>(this.url.rest  + '/tms/updateCustomerDefaultRate', rate, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }

    createDefaultRate(rate: DefaultRate): Observable<Contact> {
        return this.http.post<Contact>(this.url.rest  + '/tms/createCustomerDefaultRate', rate, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
    }


    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }

}