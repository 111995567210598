import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import { Search } from './../../models/search.model';
import { SearchService } from './../../services/search.service';
  

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ SearchService ]
})
export class SearchComponent implements OnInit {
  
  public searchKeys: Search[];
  public searchKey: Search;
  public result : number = 0;
  public frmSearch:FormGroup;
  public show: any;

  constructor(  private fb : FormBuilder,
            	private searchService : SearchService,
            	public router: Router,
            	public route: ActivatedRoute,
            	) {
            }

  ngOnInit() {
  	 this.frmSearch = this.fb.group({
        searchText: '',
    });
  	this.getSearchKeys();
  }

  public getSearchKeys() {
		
	this.searchService.getSearchKeys().subscribe(data => {
  		this.searchKeys = data["customer_search_keys"];
  		this.result = this.searchKeys.length;

		});
	}
}


